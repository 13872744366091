import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";

export const healthasasement = {
  props: ["userPermissionDataProps"],
  mixins: [globalMixin],
  data() {
    return {
      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Profile Image",
          value: "image",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
          text: "Title",
          value: "health_title",
          width: "20%",
          sortable: true,
          align: "start",
        },
        {
          text: "Health Descption",
          value: "health_descption",
          width: "20%",
          sortable: true,
          align: "start",
        },
        {
          text: "Status",
          value: "health_is_active",
          sortable: false,
          width: "20%",
          align: "start",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "25%",
          align: "end",
        },
        {
          text: "Add Test",
          value: "addvideo",
          sortable: false,
          width: "20%",
          align: "end",
        },
       
      ],

      pagination: {},
      entity: "Health Assessment",
      // search
      searchText: "",
      // add edit
      defaultItem: {},
      payLoad: {},
      totalItemsInDB: 0,
      item: {},
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Update Blog",
      addEditText: "Add",
      isLoaderActive: false,
      isDialogLoaderActive: false,
      doctorProfileImage: null,
      editdoctorProfileImage:true,
      healthBookUrl: null,
      tableItems: [],
      cityItems: [],
      tableDataLoading: false,

      //end

      //excel
      excelFields: {
        ID: "health_ass__id",
        Name: "health_title",
        Description:"health_descption",
      },
      excelFileName:
        //  "CityMaster" + "_" + moment().format("DD/MM/YYYY") + ".xls",
        "HealthasseList" + ".xls",
      //end
    };
  },
  created() {
    this.healthBookUrl = Global.healthBookUrl;
    this.getDetails();
  },
    //#region loading City, Area on page load/ mount 
    mounted() {
      // Show Add in dialog
      this.getCity();
     
  
    },
    //#endregion
  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  watch: {
    addEditDialog(value) {
      return value ? true : this.close();
    },
    pagination: {
      handler() {
        this.getDetails();
      },
      deep: true,
    },
  },
 
  methods: {
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getDetails();
      }, 500);
    },
    close() {
      this.addEditDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, this.defaultItem);
      }, 300);
      window.location.reload();
    },
    // add edit
    // addEditItem() {
    //   if (this.$refs.holdingFormAddEdit.validate()) {
    //     if (this.isAddEdit) {
    //       // save
    //       this.apiCallPost(ApiEndPoint.HealthAssasementList.savHealthAssasementList, {
    //         health_title: this.item.health_title,
    //       });
    //       this.close();
    //     } else {
    //       this.apiCallPost(ApiEndPoint.HealthAssasementList.updatHealthAssasementList, {
    //         health_title: this.item.health_title,
    //         Id: this.item.health_ass__id,
    //       });

    //       this.close();
    //     }
    //   }
    // },
    addEditItem() {

      if (this.$refs.holdingFormAddEdit.validate()) {
        if (this.isAddEdit) {
          // save
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          if (this.doctorProfileImage != null) {
            postData.append("image", this.doctorProfileImage);
          }
          postData.append("health_language", 1);
          postData.append("health_title", this.item.health_title);
          postData.append("health_descption", this.item.health_descption);
          
          
          
          ApiService.post(ApiEndPoint.HealthAssasementList.savHealthAssasementList, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDoctorDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {

          //update
          this.isDialogLoaderActive = true;
          let postData = new FormData();
           if (this.editdoctorProfileImage != true) {
            postData.append("image", this.editdoctorProfileImage);
          }
          postData.append("health_title", this.item.health_title);
          postData.append("health_descption", this.item.health_descption);
        
            postData.append("Id", this.item.health_ass__id);
          ApiService.post(ApiEndPoint.HealthAssasementList.updatHealthAssasementList, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDoctorDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },




     addVideo(item){ 
      this.$router.push({
        name: "HealthBrainAssasements",
        query: {
          health_ass__id: item.health_ass__id,
        },
      });
    },
    addLanguage(){ 
      var newurl = 'https://admin.gbsc.co.in/home/healthbook/healthassasement/HealthLanguage?language_id='+this.item.health_language.toString();
      window.location.replace(newurl);
      // this.$router.push({
      //   name: "HealthLanguage",
      //   query: {
      //     language_id: this.item.health_language.toString(),
      //   },
      // });
   
    },
    //show add edit dialog
    showAddEditDialog(item) {
      if (item == null && this.isAddEdit == true) {
        this.addEditText = `Add New ${this.entity}`;
        this.addEditDialog = true;
        this.addUpdateButtonText = " Add ";
      } else {
        //New Code Add Image Path split
        if (item.image != null) {
          let url = item.image
          let lastIndex= url.lastIndexOf('/');
          let data =url.substring(lastIndex+1);
          console.log(data);

        this.item = Object.assign({data}, item);
        this.addEditText = `Edit ${this.entity} : `;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
        // this.getCity();
       }else{
        
        this.item = Object.assign({}, item);
        this.addEditText = `Edit ${this.entity} : `;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
        // this.getCity();

      }
        // this.item = Object.assign({}, item);
        // this.addEditText = `Edit ${this.entity} : ` + item.exersice_title + item.exersice_descption;
        // this.addEditDialog = true;
        // this.addUpdateButtonText = "Update";
        // this.getCity();
      }
    },

     //#region  to load City
     getCity() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Language.getLanguage,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.cityItems = response.data.resultData;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion
    // enable disable
    async enableDisableItem(item) {
      console.log(item);
      const result = await Global.showConfirmationAlert(
        `Change  Health Assasement Status`,
        "Are you sure to change the status",
        "warning"
      );
      if (result.isConfirmed) {
        this.apiCallPost(ApiEndPoint.HealthAssasementList.changHealthAssasementListStatus, {
          Id: item.health_ass__id,
          health_is_active: item.health_is_active,
        });
      } else {
        if (item.health_is_active == false) {
          item.health_is_active = true;
        } else {
          item.health_is_active = false;
        }
      }
    },
    // #region Get Details
    getDetails() {
      console.log("Entry");

      this.isLoaderActive = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "desc" : "asc";
      sortBy = sortBy.length == 0 ? `health_ass__id` : sortBy[0];
      ApiService.get(ApiEndPoint.HealthAssasementList.getHealthAssasementList, {
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
        language_ids: 1,
      })
        .then((response) => {
          this.isLoaderActive = false;
          console.log("City", response.data.resultData.data);

          this.tableItems = response.data.resultData.data;
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          this.isLoaderActive = false;
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    // delete
    async deleteItem(item) {
      const result = await Global.showConfirmationAlert(
        `Delete Health Assasement`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
        this.apiCallPost(ApiEndPoint.HealthAssasementList.deletHealthAssasementList, {
          health_ass__id: item.health_ass__id,
        });
      }
    },

    apiCallPost(endPoint, parameter) {
      ApiService.post(endPoint, parameter)
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.success == "true") {
            Global.showSuccessAlert(true, "success", response.data.message);
            this.getDetails();
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          console.log(error);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    // #endregion
  },
};
