import { render, staticRenderFns } from "./HealthWhatWeDoLanguage.vue?vue&type=template&id=19f28862&scoped=true&"
import script from "./HealthWhatWeDoLanguage.vue?vue&type=script&lang=js&"
export * from "./HealthWhatWeDoLanguage.vue?vue&type=script&lang=js&"
import style0 from "./HealthWhatWeDoLanguage.vue?vue&type=style&index=0&id=19f28862&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19f28862",
  null
  
)

export default component.exports