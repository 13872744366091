import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
export const noOPD = {
  props: ["userPermissionDataProps"],
  mixins: [globalMixin],
  data() {
    return {
      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "No OPD/OT Date",
          value: "no_opd_date",
          width: "50%",
          sortable: true,
          align: "start",
        },
        {
          text: "Doctor",
          value: "doctor_full_name",
          width: "50%",
          sortable: true,
          align: "start",
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          width: "20%",
          align: "start",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "25%",
          align: "end",
        },
      ],

      pagination: {},
      entity: "No OPD/OT",
      // search
      searchText: "",
      // add edit
      defaultItem: {},
      payLoad: {},
      totalItemsInDB: 0,
      item: {},
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Add No OPD/OT",
      addEditText: "Add",
      isLoaderActive: false,
      isDialogLoaderActive: false,

      tableItems: [],
      tableDataLoading: false,

      //end

      //home Page clinic slot dates
            no_opd_date: new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000
            )
                .toISOString()
                .substr(0, 10),
            menu_no_opd_date: false,

    };
  },
  created() {
    this.getDetails();
  },

  //#region mounted
    mounted() {
        // To get Clinic
        this.getDoctor();
    },
    //#endregion
  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  watch: {
    addEditDialog(value) {
      return value ? true : this.close();
    },
    pagination: {
      handler() {
        this.getDetails();
      },
      deep: true,
    },
  },
  methods: {
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getDetails();
      }, 500);
    },
    close() {
      this.addEditDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, this.defaultItem);
      }, 300);
    },
    // add edit
    addEditItem() {
      if (this.$refs.holdingFormAddEdit.validate()) {
        if (this.isAddEdit) {
          // save
          this.apiCallPost(ApiEndPoint.noOPD.saveNoOPD, {
            doctor_id: this.item.doctor_id,
            no_opd_date: this.item.no_opd_date,
          });
          this.close();
        } 
      }
    },
    //show add edit dialog
    showAddEditDialog(item) {
      if (item == null && this.isAddEdit == true) {
        this.addEditText = `Add ${this.entity}`;
        this.addEditDialog = true;
        this.addUpdateButtonText = " Add ";
      } else {
        this.item = Object.assign({}, item);
        this.addEditText = `Edit ${this.entity} : ` ;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
      }
    },
    // enable disable
    async enableDisableItem(item) {
      console.log(item);
      const result = await Global.showConfirmationAlert(
        `Change  ${this.entity} Status`,
        "Are you sure to change the status",
        "warning"
      );
      if (result.isConfirmed) {
        console.log(item);
        this.apiCallPost(ApiEndPoint.noOPD.changeOPDStatus, {
          Id: item.no_opd_id,
          status: item.status,
        });
      } else {
        if (item.status == false) {
          item.status = true;
        } else {
          item.status = false;
        }
      }
    },
    // #region Get Details
    getDetails() {
      console.log("Entry");

      this.isLoaderActive = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "desc" : "asc";
      sortBy = sortBy.length == 0 ? `no_opd_id` : sortBy[0];
      ApiService.get(ApiEndPoint.noOPD.getNoOPD, {
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
      })
        .then((response) => {
          this.isLoaderActive = false;
          
          this.tableItems = response.data.resultData.data;
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          this.isLoaderActive = false;
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },

    //#region  to load Doctor
    getDoctor() {
        this.isLoaderActive = true;
        ApiService.get(
            ApiEndPoint.noOPD.getDoctorDetailsWithoutPagination,
            {}
        )
            .then((response) => {
                this.isLoaderActive = false;
                this.doctorItems = response.data.resultData;
            })
            .catch((error) => {
                this.isLoaderActive = false;
                if (error.response.status != 401 && error.response.status != 403) {
                    this.showErrorAlert(true, "error", "Something went wrong");
                }
            });
    },
    //#endregion

    // delete
    async deleteItem(item) {
      const result = await Global.showConfirmationAlert(
        `Delete`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
        this.apiCallPost(ApiEndPoint.noOPD.deleteNoOPD, {
          no_opd_id: item.no_opd_id,
        });
      }
    },

    apiCallPost(endPoint, parameter) {
      ApiService.post(endPoint, parameter)
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.success == "true") {
            Global.showSuccessAlert(true, "success", response.data.message);
            this.getDetails();
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          console.log(error);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    // #endregion
  },
};
