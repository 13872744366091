export const ApiEndPoint = {
  // #region Authentication Api End Point
  Authentication: {
    login: "webValidateLogin",
    logout: "logout",
    changePassword: "changePassword",
  },
  // #endregion

  //#region  User End point
  User: {
    getUser: "users",
    saveUser: "save",
  },
  //#endregion
  // <-------------------------------------MASTER------------------------------------------------------>
  //#region  City End point
  City: {
    getCity: "getCity",
    saveCity: "saveCity",
    updateCity: "updateCity",
    changeCityStatus: "changeCityStatus",
    deleteCity: "deleteCity",
  },
  //#endregion

  //Health book section
  //#region  Healthbook exersice End point
  Exersice: {
    getExersice: "getExersice",
    savExersice: "savExersice",
    updatExersice: "updatExersice",
    changExersiceStatus: "changExersiceStatus",
    deletExersice: "deletExersice",
  },
  //#endregion

  //#region  Healthbook exersice video End point
  ExersiceVideo: {
    getExersiceVideo: "getExersiceVideo",
    savExersiceVideo: "savExersiceVideo",
    updatExersiceVideo: "updatExersiceVideo",
    changExersiceVideoStatus: "changExersiceVideoStatus",
    deletExersiceVideo: "deletExersiceVideo",
  },
  //#endregion


   //#region  Healthbook tips video End point
  HealthTips: {
    getHealthTips: "getHealthTips",
    savHealthTips: "savHealthTips",
    updatHealthTips: "updatHealthTips",
    changHealthTipsStatus: "changHealthTipsStatus",
    deletHealthTips: "deletHealthTips",
  },
  //New Patient Tips Video Add 19/09 
  PatientTipsvideo: {
    getpatientTips: "getpatientTips",
    savePatientTips: "savePatientTips",
    updatPatientTips: "updatPatientTips",
    changPatientTipsStatus: "changPatientTipsStatus",
    deletPatientTips: "deletPatientTips",
  },
  //#endregion

  //#region  Language End point
  Language: {
    getLanguage: "getLanguage",
  },
  //#endregion

   //#region  Healthbook Article video End point
  ArticleBlog: {
    getArticleBlog: "getArticleBlog",
    savArticleBlog: "savArticleBlog",
    updatArticleBlog: "updatArticleBlog",
    changArticleBlogStatus: "changArticleBlogStatus",
    deletArticleBlog: "deletArticleBlog",
  },
  //#endregion

    //#region  Healthbook FAQ End point
  HealthFaq: {
    getHealthFaq: "getHealthFaq",
    savHealthFaq: "savHealthFaq",
    updatHealthFaq: "updatHealthFaq",
    changHealthFaqStatus: "changHealthFaqStatus",
    deletHealthFaq: "deletHealthFaq",
  },
  //#endregion

  //#region  Healthbook faq video End point
  HealthFaqVideo: {
    getHealthFaqVideo: "getHealthFaqVideo",
    savHealthFaqVideo: "savHealthFaqVideo",
    updatHealthFaqVideo: "updatHealthFaqVideo",
    changHealthFaqVideoStatus: "changHealthFaqVideoStatus",
    deletHealthFaqVideo: "deletHealthFaqVideo",
  },
  //#endregion

    //#region  Healthbook faq video End point
  HealthWhatWeDoVideo: {
    getHealthWhatWeDoVideo: "getHealthWhatWeDoVideo",
    savHealthWhatWeDoVideo: "savHealthWhatWeDoVideo",
    updatHealthWhatWeDoVideo: "updatHealthWhatWeDoVideo",
    changHealthWhatWeDoVideoStatus: "changHealthWhatWeDoVideoStatus",
    deletHealthWhatWeDoVideo: "deletHealthWhatWeDoVideo",
  },
  //#endregion


     //#region  Healthbook faq video List End point
  HealthWhatWeDoVideoList: {
    getHealthWhatWeDoVideoList: "getHealthWhatWeDoVideoList",
    savHealthWhatWeDoVideoList: "savHealthWhatWeDoVideoList",
    updatHealthWhatWeDoVideoList: "updatHealthWhatWeDoVideoList",
    changHealthWhatWeDoVideoListStatus: "changHealthWhatWeDoVideoListStatus",
    deletHealthWhatWeDoVideoList: "deletHealthWhatWeDoVideoList",
  },
  //#endregion


  //#region  Healthbook faq video List End point
  HealthAssasementList: {
    getHealthAssasementList: "getHealthAssasementList",
    savHealthAssasementList: "savHealthAssasementList",
    updatHealthAssasementList: "updatHealthAssasementList",
    changHealthAssasementListStatus: "changHealthAssasementListStatus",
    deletHealthAssasementList: "deletHealthAssasementList",
  },
  //#endregion

  //#region  Healthbook faq video List End point
  HealthMedicalList: {
    getHealthMedicalList: "getHealthMedicalList",
    savHealthMedicalList: "savHealthMedicalList",
    updatHealthMedicalList: "updatHealthMedicalList",
    changHealthMedicalListStatus: "changHealthMedicalListStatus",
    deletHealthMedicalList: "deletHealthMedicalList",
  },
  //#endregion
  
    //#region  Healthbook faq video List End point
    HealthBookSliderList: {
      getHealthBookSliderList: "getHealthBookSliderList",
      savHealthBookSliderList: "savHealthBookSliderList",
      updatHealthBookSliderList: "updatHealthBookSliderList",
      changHealthBookSliderListStatus: "changHealthBookSliderListStatus",
      deletHealthBookSliderList: "deletHealthBookSliderList",
    },
     //#endregion
     

    //#region  Healthbook faq video List End point
    HomeSliderList: {
      getHomeSliderList: "getHomeSliderList",
      savHomeSliderList: "savHomeSliderList",
      updatHomeSliderList: "updatHomeSliderList",
      changHomeSliderListStatus: "changHomeSliderListStatus",
      deletHomeSliderList: "deletHomeSliderList",
    },
    //#endregion
  //#region  Area End point
  Area: {
    getArea: "getArea",
    saveArea: "saveArea",
    updateArea: "updateArea",
    changeAreaStatus: "changeAreaStatus",
    deleteArea: "deleteArea",
  },
  //#endregion

  //#region Doctor End point
  Doctor: {
    getCityIdWithoutPagination: "getCityIdWithoutPagination",
    getAreaIdWithoutPagination: "getAreaIdWithoutPagination",
    webGetDoctorDetails: "webGetDoctorDetails",
    saveDoctorDetails: "saveDoctorDetails",
    webUpdateDoctorDetails: "webUpdateDoctorDetails",
    changeDoctorDetailsStatus: "changeDoctorDetailsStatus",
    deleteDoctorDetails: "deleteDoctorDetails",
    webGetDiseaseCategory: "webGetDiseaseCategory",
    getAreaIdWithoutPaginations:"getAreaIdWithoutPaginations",
    GetWithoutPaginationJuniorDoctor:"GetWithoutPaginationJuniorDoctor",
    GetWithoutPaginationCoordinator:"GetWithoutPaginationCoordinator"
  },
  //#endregion

  //#region Ip Chat
  ipModule: {
    addIpModules: "addIpModules",
    updatIpModules:"updatIpModules",
    getIpModule: "getIpModule",
    getIpIcuIdWithoutPagination: "getIpIcuIdWithoutPagination",
    getIpSponsorIdWithoutPagination: "getIpSponsorIdWithoutPagination",
    getIpPatientIdWithoutPagination: "getIpPatientIdWithoutPagination",
    getIpDoctorIdWithoutPagination: "getIpDoctorIdWithoutPagination",
    getIpJrDoctorIdWithoutPagination:"getIpJrDoctorIdWithoutPagination",
    getIpStaffIdWithoutPagination:"getIpStaffIdWithoutPagination",
    changeIpModuleStatus: "changeIpModuleStatus",
    addDoctorInIpModule:"addDoctorInIpModule",
    addJrDoctorInIpModule:"addJrDoctorInIpModule",
    addStaffInIpModule:"addStaffInIpModule",
    getAllIpFamilyMemberList: "getAllIpFamilyMemberList",
    getAllIpRelationList: "getAllIpRelationList",
    addFamilyMemberInIpModule: "addFamilyMemberInIpModule",
    getAllIpModuleMembers: "getAllIpModuleMembers",
    changeIpMemberStatus: "changeIpMemberStatus",
    deleteIPMemberByAdmin: "deleteIPMemberByAdmin",
  },

  //#endregion
  
  // Clinic Staff
  Clinicstaff:{
    webGetDoctorCategory:"webGetDoctorCategory",
    webGetJuniorCategory:"webGetJuniorCategory",
    webGetNursingCategory:"webGetNursingCategory",
    webGetDoctor: "webGetDoctor",
    saveclinicstaff:"saveclinicstaff",
    deleteClinicDetails: "deleteClinicDetails",
    changeClinicDetailsStatus: "changeClinicDetailsStatus",
  },
  //end
  //#region Clinic
  Clinic: {
    webGetClinicDetails: "webGetClinicDetails",
    webSaveClinicDetails: "webSaveClinicDetails",
    webUpdateClinicDetails: "webUpdateClinicDetails",
    webChangeClinicActive: "webChangeClinicActive",
    webChangeMasterClinic: "webChangeMasterClinic",
    getCityIdWithoutPagination: "getCityIdWithoutPagination",
    getAreaIdWithoutPagination: "getAreaIdWithoutPagination",

    webGetClinicService: "webGetClinicService",
    webSaveClinicService: "webSaveClinicService",
    webUpdateClinicService: "webUpdateClinicService",

    webGetClinicTiming: "webGetClinicTiming",
    webSaveClinicTiming: "webSaveClinicTiming",
    webUpdateClinicTiming: "webUpdateClinicTiming",
  },
  //#endregion
  //#region Nursing
  Nursing: {
    webGetNursingDetails: "webGetNursingDetails",
    webChangeNursingActive:"webChangeNursingActive",
    webSaveNursingDetails:"webSaveNursingDetails",
    webUpdatestaffDetails:"webUpdatestaffDetails",
    deleteNursingDetails:"deleteNursingDetails",
  },
  Icu: {
    webGetIcuDetails: "webGetIcuDetails",
    webChangeIcuActive:"webChangeIcuActive",
    webSaveIcuDetails:"webSaveIcuDetails",
    webUpdateIcuDetails:"webUpdateIcuDetails",
    deleteIcuDetails:"deleteIcuDetails",
  },

  juniorDoctor: {
    webGetjuniorDoctorDetails: "webGetjuniorDoctorDetails",
    webChangejuniorDoctorActive:"webChangejuniorDoctorActive",
    webSavejuniorDoctorDetails:"webSavejuniorDoctorDetails",
    webUpdatejuniorDoctorDetails:"webUpdatejuniorDoctorDetails",
    deletejuniorDoctorDetails:"deletejuniorDoctorDetails",
    getWithoutPaginationDoctor:"getWithoutPaginationDoctor",
  },
  //#endregion
  // <-------------------------------------END------------------------------------------------------>

  // <-------------------------------------TRANSACTION------------------------------------------------------>
  //#region Block Dates
  SlotDates: {
    getSlotDates: "getSlotDates",
    saveSlotDate: "saveSlotDate",
    changeSlotStatus: "changeSlotStatus",
    changeDateSlotsStatus: "changeDateSlotsStatus",
    clinicActiveDetails: "clinicActiveDetails",
    doctorActiveDetails: "doctorActiveDetails",
    deleteSlotDate: "deleteSlotDate",
    webUpdateSlotDate: "webUpdateSlotDate",
  },
  //#endregion
  //#region VideoBookingDetails
  VideoSlotDate: {

    webGetVideoSlotDates: "webGetVideoSlotDates",
    webSaveVideoSlotDates: "webSaveVideoSlotDates",
    webChangeVideoBookingAvailable: "webChangeVideoBookingAvailable",
    webChangeVideoSlotDatesActive: "webChangeVideoSlotDatesActive",
    getDoctorDetailsWithoutPagination: "getDoctorDetailsWithoutPagination",
    webUpdateVideoSlotDate: "webUpdateVideoSlotDate",
  },
  //#endregion
  //#region VideoBookingDetails
  VideoBookingDetails: {
    getVideoBookingDetails: "getVideoBookingDetails",
    getDoctorDetailsWithoutPagination: "getDoctorDetailsWithoutPagination",
  },
  //#endregion
  //#region Upcoming Video Booking Details
  UpcomingVideoBookingDetails: {
    getUpcomingVideoBookingDetails: "getUpcomingVideoBookingDetails",
  },
  //#endregion BookMedicine
  //#region 
  BookMedicine: {
    webGetBookMedicine: "webGetBookMedicine",
    webChangeBookMedicineStatus: "webChangeBookMedicineStatus",
    webGetPrescriptionDoc: "webGetPrescriptionDoc",
  },
  //#endregion
   //#region 
   BookTest: {
    webGetBookTest: "webGetBookTest",
    webChangeBookTestStatus: "webChangeBookTestStatus",
    webGetTestDoc: "webGetTestDoc",
  },
  //#endregion
  // <-------------------------------------END------------------------------------------------------>

  // <-------------------------------------Report------------------------------------------------------>
  //#region  BookingDetails
  BookingDetails: {
    getBookingDetails: "getBookingDetails",
    clinicActiveDetails: "clinicActiveDetails",
    doctorActiveDetails: "doctorActiveDetails",
  },
  //#endregion
  //#region  ClinicWiseBooking
  ClinicWiseBooking: {
    clinicActiveDetails: "clinicActiveDetails",
    clinicWiseBooking: "clinicWiseBooking",

  },
  //#endregion

  //#region  UpcomingBookingDetails
  UpcomingBookingDetails: {
    clinicActiveDetails: "clinicActiveDetails",
    upcomingBookingDetails: "upcomingBookingDetails",
  },
  //#endregion

  //#region Reg Patient
  RegPatient: {
    webGetRegisteredPatient: "webGetRegisteredPatient",
    webGetInClinicBookingForPatient: "webGetInClinicBookingForPatient",
    webGetVideoBookingForPatient: "webGetVideoBookingForPatient",
  },
  //#endregion

  //#region Video Visit Clinic Wise
  VideoVisitClinicWise: {
    webGetVideoVisitClinicWise: "webGetVideoVisitClinicWise",
  },
  //#endregion

  //#region Doctor Wise Patient Details
  DoctorWisePatientDetails: {
    webGetDoctorWisePatientDetails: "webGetDoctorWisePatientDetails",
  },
  //#endregion

  //#region Patient Wise Booking
  PatientWiseBooking: {
    webGetPatientWiseBookingDetails: "webGetPatientWiseBookingDetails",
  },
  //#endregion

  //#region Reg Patient
  RegisterPatient: {
    getRegisteredPatient: "getRegisteredPatient",
    admitPatientByAdmin: "admitPatientByAdmin",
    UpdateAdmitPatientDetails: "UpdateAdmitPatientDetails",
    getPatientListWithoutUhid: "getPatientListWithoutUhid",
  },
  
  //#region Group Chat
  chatGroup: {
    addChatGroup: "addChatGroup",
    getChatGroup: "getChatGroup",
    getDoctorIdWithoutPagination: "getDoctorIdWithoutPagination",
    getPatientIdWithoutPagination: "getPatientIdWithoutPagination",
    changeGroupStatus: "changeGroupStatus",
    addDoctorInGroup:"addDoctorInGroup",
    getAllFamilyMemberList: "getAllFamilyMemberList",
    getAllRelationList: "getAllRelationList",
    addFamilyMemberInGroup: "addFamilyMemberInGroup",
    getAllGroupMembers: "getAllGroupMembers",
    changeMemberStatus: "changeMemberStatus",
    deleteMemberByAdmin: "deleteMemberByAdmin",
  },

  //#endregion

   //#region No OPD/OT
  noOPD: {
    getNoOPD: "getNoOPD", 
    getDoctorDetailsWithoutPagination: "getDoctorDetailsWithoutPagination",
    saveNoOPD: "saveNoOPD",
    changeOPDStatus: "changeOPDStatus",
    deleteNoOPD: "deleteNoOPD",
  },
  //#endregion

  //#region Dashboard
  Dashboard: {
    getOpdBooking: "getOpdBooking", 
    getVideoBooking: "getVideoBooking",
    getDoctorIdWithoutPagination: "getDoctorIdWithoutPagination",
    getClinicListWithoutPagination: "getClinicListWithoutPagination",
    getNoOtOpd: "getNoOtOpd",
  },
  //#endregion

  //region notification      
     NotificationSetting: {
      getNotificationSetting: "getNotificationSetting",
      savNotificationSetting: "savNotificationSetting",
      updatNotificationSetting: "updatNotificationSetting",
      changNotificationSettingStatus: "changNotificationSettingStatus",
      deletNotificationSetting: "deletNotificationSetting",
      sendNotificationSetting: "sendNotificationSetting",
    },  
   

  //end notification

  PaymentDetails: {
      GetPaymentDetails: "GetPaymentDetails",
      UpdatePaymentDetails: "UpdatePaymentDetails",
    },
    
  //#region Pharmacy
  Pharmacy:{
    webGetPharmacyDetails: "webGetPharmacyDetails",
    webChangePharmacyActive: "webChangePharmacyActive",
    webSavePharmacyDetails: "webSavePharmacyDetails",
    webUpdatePharmacyDetails: "webUpdatePharmacyDetails",
    deletePharmacyDetails: "deletePharmacyDetails",
  },
  //#end Pharmacy
  // <-------------------------------------END------------------------------------------------------>
};
