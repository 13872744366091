import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";

export const homepagesliderinfo = {
  props: ["userPermissionDataProps"],
  mixins: [globalMixin],
  data() {
    return {
      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Slider Image",
          value: "slider_image",
          width: "40%",
          sortable: true,
          align: "start",
        },
        {
          text: "Status",
          value: "slider_is_active",
          width: "10%",
          sortable: true,
          align: "start",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "10%",
          align: "end",
        },
       
      ],

      pagination: {},
      entity: "Home Page Slider",
      // search
      searchText: "",
      // add edit
      defaultItem: {},
      payLoad: {},
      totalItemsInDB: 0,
      item: {},
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Update Home Page Slider",
      addEditText: "Add",
      isLoaderActive: false,
      isDialogLoaderActive: false,
      sliderImage: null,
      homeSliderUrl: null,
      tableItems: [],
      tableDataLoading: false,

      //end

    };
  },
  created() {
    this.homeSliderUrl = Global.homeSliderUrl;
    this.getDetails();
  },
  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  watch: {
    addEditDialog(value) {
      return value ? true : this.close();
    },
    pagination: {
      handler() {
        this.getDetails();
      },
      deep: true,
    },
  },
  methods: {
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getDetails();
      }, 500);
    },
    close() {
      this.addEditDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, this.defaultItem);
      }, 300);
    },
    // add edit
    // addEditItem() {
    //   if (this.$refs.holdingFormAddEdit.validate()) {
    //     if (this.isAddEdit) {
    //       // save
    //       this.apiCallPost(ApiEndPoint.HealthTips.savHealthTips, {
    //         video_url: this.item.video_url,
    //         video_title: this.item.video_title,
    //         video_description: this.item.video_description,
    //       });
    //       this.close();
    //     } else {
    //       this.apiCallPost(ApiEndPoint.HealthTips.updatHealthTips, {
    //         video_url: this.item.video_url,
    //         video_title: this.item.video_title,
    //         video_description: this.item.video_description,
    //         Id: this.item.healthbook_tips_id,
    //       });

    //       this.close();
    //     }
    //   }
    // },
    addEditItem() {

      if (this.$refs.holdingFormAddEdit.validate()) {
        if (this.isAddEdit) {
          // save
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          if (this.sliderImage != null) {
            postData.append("slider_image", this.sliderImage);
          }
       
                   
          ApiService.post(ApiEndPoint.HomeSliderList.savHomeSliderList, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {

          //update
          this.isDialogLoaderActive = true;
          let postData = new FormData();
           if (this.sliderImage != null) {
            postData.append("slider_image", this.sliderImage);
          }
          
          postData.append("Id", this.item.slider_id);
          ApiService.post(ApiEndPoint.HomeSliderList.updatHomeSliderList, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
               this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },
    
    //show add edit dialog
    showAddEditDialog(item) {
      if (item == null && this.isAddEdit == true) {
        this.addEditText = `Add New ${this.entity}`;
        this.addEditDialog = true;
        this.addUpdateButtonText = " Add ";
      } else {
        this.item = Object.assign({}, item);
        this.addEditText = `Edit ${this.entity}`;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
      }
    },
    // enable disable
    async enableDisableItem(item) {
      console.log(item);
      const result = await Global.showConfirmationAlert(
        `Change  Slider Status`,
        "Are you sure to change the status",
        "warning"
      );
      if (result.isConfirmed) {
        this.apiCallPost(ApiEndPoint.HomeSliderList.changHomeSliderListStatus, {
          Id: item.slider_id,
          slider_is_active: item.slider_is_active,
        });
      } else {
        if (item.slider_is_active == false) {
          item.slider_is_active = true;
        } else {
          item.slider_is_active = false;
        }
      }
    },
    // #region Get Details
    getDetails() {
      console.log("Entry");

      this.isLoaderActive = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "desc" : "asc";
      sortBy = sortBy.length == 0 ? `slider_id` : sortBy[0];
      ApiService.get(ApiEndPoint.HomeSliderList.getHomeSliderList, {
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
      })
        .then((response) => {
          this.isLoaderActive = false;
        
          this.tableItems = response.data.resultData.data;
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          this.isLoaderActive = false;
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    // delete
    async deleteItem(item) {
      const result = await Global.showConfirmationAlert(
        `Delete Slider`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
        this.apiCallPost(ApiEndPoint.HomeSliderList.deletHomeSliderList, {
          Id: item.slider_id,
        });
      }
    },

    apiCallPost(endPoint, parameter) {
      ApiService.post(endPoint, parameter)
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.success == "true") {
            Global.showSuccessAlert(true, "success", response.data.message);
            this.getDetails();
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          console.log(error);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    // #endregion
  },
};
