import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
export const viewvideo = {
  props: ["userPermissionDataProps", "addDoctorDataProps"],
  mixins: [globalMixin],
  //#region  Data section
  data() {
    return {
      // Data Table

      
      
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Video Link",
          value: "video_link",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
          text: "Profile Image",
          value: "profile_image",
          width: "15%",
          sortable: true,
          align: "start",
        },
       
        
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "15%",
          align: "end",
        },
      ],

      pagination: {},
      entity: "Add Video",
      searchText: "", // search
      totalItemsInDB: 0,
      item: {},
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Add Doctor",
      addEditText: "Add",
      isLoaderActive: false,
      isDialogLoaderActive: false,

      tableItems: [],
      cityItems: [],
      areaItems: [],
      diseaseCategoryItems: [],
      imageRule: [],
      doctorProfileImage: null,
      profileImageUrl: null,
      tableDataLoading: false,

      //end

      //excel
      // excelFields: {
      //   doctor_full_name: "doctor_full_name",
      //   doctor_profile_image: "doctor_profile_image",
      //   doctor_mobile_number: "doctor_mobile_number",
      //   specialization_name: "specialization_name",
      //   doctor_overall_experience: "doctor_overall_experience",
      //   Status: "doctor_is_active",
      // },
      excelFileName:
        "Doctor" + "_" + moment().format("DD/MM/YYYY") + ".xls",
      //end
    };

  },
  //#endregion

  //#region  created section
  created() {
    //  get the Doctor Details List
    this.healthwhat_ids = this.$route.query.healthwhat_id;
    this.profileImageUrl = Global.profileImageUrl;
    this.getDoctorDetailsList();


  },
  //#endregion

  //#region Computed section
  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  //#endregion

  //#region watch setion
  watch: {
    //#region  add/edit dialog
    addEditDialog(value) {
      return value ? true : this.close();
    },
    //#endregion

    //#region 
    doctorProfileImage(val) {
      this.doctorProfileImage = val;
      this.imageRule =
        this.doctorProfileImage != null
          ? [(v) => !v || v.size <= 1048576 || "File size should be 1MB"]
          : [];
    },
    //#endregion

    //#region Pagination
    pagination: {
      handler() {
        this.getDoctorDetailsList();
      },
      deep: true,
    },
    //#endregion
  },
  //#endregion


  //#region loading City, Area on page load/ mount 
  mounted() {
    // Show Add in dialog
    this.getCity();
    this.getDiseaseCategory();

  },
  //#endregion

  //#region Method section
  methods: {
    //#region To get the Doctor Details List
    getDoctorDetailsList() {
      this.tableDataLoading = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "desc" : "asc";
      sortBy = sortBy.length == 0 ? `video_id` : sortBy[0];
      ApiService.get(ApiEndPoint.HealthWhatWeDoVideoList.getHealthWhatWeDoVideoList, {
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
         healthwhat_ids: this.healthwhat_ids,

      })
        .then((response) => {
          this.tableDataLoading = false;
          this.tableItems = response.data.resultData.data;
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          this.tableDataLoading = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  search
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getDoctorDetailsList();
      }, 500);
    },
    //#endregion

    //#region  show add/edit dialog
    async showAddEditDialog(item) {

      // Show Add
      if (item == null && this.isAddEdit == true) {
        this.addEditText = `Add ${this.entity}`;
        this.addEditDialog = true;
        this.addUpdateButtonText = " Add ";
        this.CityId = null;
        this.AreaId = null;
        this.DiseaseCategoryId = null;
      } else {
        // Show Edit (Update)
        this.item = Object.assign({}, item);
        this.addEditText = `Edit ${this.entity} : ` + item.doctor_full_name;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
        this.getCity();
        this.getArea();
        this.getDiseaseCategory();
      }
    },
    //#endregion

    //#region  to load City
    getCity() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Doctor.getCityIdWithoutPagination,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.cityItems = response.data.resultData;
          this.CityId=this.item != null  ? this.item.city_id.split(",").map(Number) : null;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion


    //#region  to load Area
    async getArea() {
      this.isDialogLoaderActive = true;
      try {

        // this.CityId = this.item != null 
        //   ? this.item.city_id.split(",").map(Number)
        //   : null

        const response = await ApiService.get(
          ApiEndPoint.Doctor.getAreaIdWithoutPagination,
          {city_id: this.CityId!=null? this.CityId.toString():null}
        )
        this.areaItems = response.data.resultData;
        this.isDialogLoaderActive = false;
        this.AreaId=this.item != null  ? this.item.area_id.split(",").map(Number) : null;

      } catch (error) {
        this.isDialogLoaderActive = false;
        if (error.response.status != 401 && error.response.status != 403) {
          this.showErrorAlert(true, "error", "Something went wrong");
        }
      }
    },
    //#endregion

    //#region  to load City
    async getDiseaseCategory() {
      this.isDialogLoaderActive = true;
      try {
        const response = await ApiService.get(
          ApiEndPoint.Doctor.webGetDiseaseCategory,
          {}
        )
        this.isDialogLoaderActive = false;
        this.diseaseCategoryItems = response.data.resultData;
        
        this.DiseaseCategoryId=this.item != null 
          ? this.item.disease_category_id.split(",").map(Number)
          : null

      } catch (error) {
        this.isDialogLoaderActive = false;
        if (error.response.status != 401 && error.response.status != 403) {
          this.showErrorAlert(true, "error", "Something went wrong");
        }
      }
    },
    //#endregion

    //#region  add/edit item
    addEditItem() {

      if (this.$refs.holdingFormAddEdit.validate()) {
        if (this.isAddEdit) {
          // save
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          if (this.doctorProfileImage != null) {
            postData.append("profile_image", this.doctorProfileImage);
          }
           postData.append("video_link", this.item.video_link);
            postData.append("healthwhat_ids", this.healthwhat_ids);
          

          ApiService.post(ApiEndPoint.HealthWhatWeDoVideoList.savHealthWhatWeDoVideoList, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDoctorDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {

          //update
          this.isDialogLoaderActive = true;
          let postData = new FormData();
           if (this.doctorProfileImage != null) {
            postData.append("profile_image", this.doctorProfileImage);
          }
           postData.append("video_link", this.item.video_link);
            postData.append("healthwhat_ids", this.healthwhat_ids);
            postData.append("Id", this.item.video_id);
          ApiService.post(ApiEndPoint.HealthWhatWeDoVideoList.updatHealthWhatWeDoVideoList, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDoctorDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },

    //#endregion

    //#region  to close the dialog
    close() {
      this.addEditDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, {
          cityItems: this.cityItems,
          areaItems: this.areaItems,
        });
      }, 300);
    },
    //#endregion

    //#region  enable disable
    async enableDisableItem(item) {
      const result = await Global.showConfirmationAlert(
        `Change  ${this.entity} : ${item.doctor_full_name} Status`,
        "Are you sure to change the status",
        "warning"
      );
      if (result.isConfirmed) {
        this.isLoaderActive = true;

        ApiService.post(
          ApiEndPoint.Doctor.changeDoctorDetailsStatus, {
          doctor_id: item.doctor_id,
          doctor_is_active: item.doctor_is_active,
        }
        )
          .then((response) => {
            this.isLoaderActive = false;
            if (response.data.success == "true") {
              Global.showSuccessAlert(true, "success", response.data.message);
              this.getDoctorDetailsList();
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;

            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        if (item.doctor_is_active == false) {
          item.doctor_is_active = true;
        } else {
          item.doctor_is_active = false;
        }
      }
    },
    //#endregion

    //#region Delete Item
    async deleteItem(item) {
      const result = await Global.showConfirmationAlert(
        `Delete Video`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
        this.isLoaderActive = true;
        ApiService.post(ApiEndPoint.HealthWhatWeDoVideoList.deletHealthWhatWeDoVideoList, {
           video_id: item.video_id,
        })
          .then((response) => {
            this.isLoaderActive = false;
            if (response.data.success == "true") {
              Global.showSuccessAlert(true, "success", response.data.message);
              this.getDoctorDetailsList();
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;

            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    //#endregion
  },
  //#endregion
};
