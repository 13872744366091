import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
export const pharmacy = {
  props: ["userPermissionDataProps","addPharmacyDataProps"],
  mixins: [globalMixin],
  //#region  Data section
  data() {
    return {
      // PageId:
      // this.addPharmacyDataProps != null 
      //   ? this.addPharmacyDataProps.item.page_access.split(",")
      //   : null,
      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Name",
          value: "pharmacy_full_name",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
          text: "Device Profile Image",
          value: "pharmacy_profile_image",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
          text: "Mobile",
          value: "pharmacy_mobile",
          width: "15%",
          sortable: true,
          align: "start",
        },
       
        {
            text: "Status",
            value: "pharmacy_is_active",
            sortable: false,
            width: "15%",
            align: "start",
        },
        
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "30%",
          align: "end",
        },

      ],

      pagination: {},
      entity: "Pharmacy",
      searchText: "", // search
      totalItemsInDB: 0,
      item: {},
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Add Pharmacy",
      addEditText: "Add",
      isLoaderActive: false,
      isDialogLoaderActive: false,

      tableItems: [],
      pageAccess: ['DashboardModule','IpModule'],
      areaItems: [],
      diseaseCategoryItems: [],
      imageRule: [],
      doctorProfileImage: null,
      editdoctorProfileImage:true,
      NursingUrl: null,
      tableDataLoading: false,

      //end

      //excel
      excelFields: {
        pharmacy_full_name: "pharmacy_full_name",
       
        
      },
      excelFileName:
        "PharmacyMaster" + "_" + moment().format("DD/MM/YYYY") + ".xls",

      //end
    };
  },
  //#endregion
  //#region  created section
  created() {
    //#get Clinic Details List
    this.getPharmacyDetailsList();
    
  },
  //#endregion

  //#region loading City, Area on page load/ mount 
  mounted() {
    // Show Add in dialog
    // this.getCity();
    this.getClinicService();
    this.getClinicTiing();

  },
  //#endregion

  //#region Computed section
  computed: {
    //#region  Numbering data table row
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
    //#endregion
  },
  //#endregion
  //#region watch setion
  watch: {
    //#region  add/edit dialog
    addEditDialog(value) {
      return value ? true : this.close();
    },
    //#endregion
    //#region  add/edit dialog
    addServiceDialog(value) {
      return value ? true : this.close();
    },
    //#endregion
    //#region Pagination
    pagination: {
      handler() {
        this.getPharmacyDetailsList();
      },
      deep: true,
    },
    //#endregion
  },
  //#endregion

  //#region Method section
  methods: {
    checkKeyDownAlphaNumeric(event) {
      if (!/[a-zA-Z\s]/.test(event.key)) {
        this.ignoredValue = event.key ? event.key : "";
        event.preventDefault();
      }
    },

    //#region To get the Clinic Details
    getPharmacyDetailsList() {
      this.tableDataLoading = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "desc" : "asc";
      sortBy = sortBy.length == 0 ? `pharmacy_id` : sortBy[0];
      ApiService.get(ApiEndPoint.Pharmacy.webGetPharmacyDetails, {
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
      })
        .then((response) => {
          this.tableDataLoading = false;

          this.tableItems = response.data.resultData.data;
          console.log(response);
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          this.tableDataLoading = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region To get the Clinic Service
    getClinicService() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Clinic.webGetClinicService,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          console.log(response);
          this.clinicServiceItems = response.data.resultData;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });


    },
    //#endregion

    //#region To get the Clinic Timing
    getClinicTiing() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Clinic.webGetClinicTiming,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          console.log(response);
          this.clinicTimingItems = response.data.resultData;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });


    },
    //#endregion

    //#region  search
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getPharmacyDetailsList();
      }, 500);
    },

    //#endregion

    //#region  show add/edit dialog
    showAddEditDialog(item) {

      if (item == null && this.isAddEdit == true) {
        this.addEditText = `Add  ${this.entity}`;
        this.addEditDialog = true;
        this.addUpdateButtonText = " Add ";
        // this.PageId = null;
      } else {
        if (item.pharmacy_profile_image != null) {
          let url = item.pharmacy_profile_image
          let lastIndex= url.lastIndexOf('/');
          let data =url.substring(lastIndex+1);
          console.log(data);

        this.item = Object.assign({data}, item);
        this.addEditText = `Edit ${this.entity} : ` + item.pharmacy_full_name;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
        // this.PageId=this.item != null  ? this.item.page_access.split(",") : null;
        }else{
        this.item = Object.assign({}, item);
        this.addEditText = `Edit ${this.entity} : ` + item.pharmacy_full_name;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
        // this.PageId=this.item != null  ? this.item.page_access.split(",") : null;
        }
      }
    },
    //#endregion

    //#region  show add Service dialog
    showAddServiceDialog(item) {
      if (item == null && this.isAddService == true) {
        this.addServiceText = `Add  ${this.entity}`;
        this.addServiceDialog = true;
        this.addUpdateButtonText = " Add ";
      } else {
        this.item = Object.assign({}, item);
        this.addServiceText = `Edit ${this.addServiceText} : ` + item.clinic_full_name;
        this.addServiceDialog = true;
        this.addUpdateButtonText = "Update";
        this.getClinicService();
      }
    },
    //#endregion

    //#region  show add Timing dialog
    showAddTimingDialog(item) {
      
      if (item == null && this.isAddTiming == true) {
        this.addTimingText = `Add  ${this.entity}`;
        this.addTimingDialog = true;
        this.addUpdateButtonText = " Add ";
      } else {
        
        this.item = Object.assign({}, item);
        this.addTimingText = `Edit ${this.addTimingText} : ` + item.clinic_full_name;
        this.addTimingDialog = true;
        this.addUpdateButtonText = "Update";
        this.getClinicTiming();
      }
    },
    //#endregion

    //#region  to load City
    // getCity() {
    //   this.isDialogLoaderActive = true;
    //   ApiService.get(
    //     ApiEndPoint.Clinic.getCityIdWithoutPagination,
    //     {}
    //   )
    //     .then((response) => {
    //       this.isDialogLoaderActive = false;
    //       this.cityItems = response.data.resultData;
    //     })
    //     .catch((error) => {
    //       this.isDialogLoaderActive = false;
    //       if (error.response.status != 401 && error.response.status != 403) {
    //         this.showErrorAlert(true, "error", "Something went wrong");
    //       }
    //     });
    // },
    //#endregion

    //#region  to load Area
    async getArea() {
      this.isDialogLoaderActive = true;
      try {
        const response = await ApiService.get(
          ApiEndPoint.Clinic.getAreaIdWithoutPagination,
          { city_id: this.item.city_id.toString() }
        )
        this.areaItems = response.data.resultData;
        this.isDialogLoaderActive = false;
      } catch (error) {
        this.isDialogLoaderActive = false;
        if (error.response.status != 401 && error.response.status != 403) {
          this.showErrorAlert(true, "error", "Something went wrong");
        }
      }
    },
    //#endregion

    //#region  add/edit item
    addEditItem() {

      if (this.$refs.holdingFormAddEdit.validate()) {
        if (this.isAddEdit) {
          // save
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          if (this.doctorProfileImage != null) {
            postData.append("pharmacy_profile_image", this.doctorProfileImage);
          }
          postData.append("pharmacy_first_name", this.item.pharmacy_first_name);
          postData.append("pharmacy_last_name", this.item.pharmacy_last_name);
          postData.append("pharmacy_mobile", this.item.pharmacy_mobile);
          // postData.append("page_access", this.item.page_access);
          // postData.append("page_access", this.PageId!=null? this.PageId.toString():null);
          // postData.append("page_access", this.pageId!=null? this.pageId.toString():null);

          ApiService.post(ApiEndPoint.Pharmacy.webSavePharmacyDetails, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getPharmacyDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {

          //update
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          if (this.editdoctorProfileImage != true) {
            postData.append("pharmacy_profile_image", this.editdoctorProfileImage);
          }
        
          postData.append("pharmacy_first_name", this.item.pharmacy_first_name);
          postData.append("pharmacy_last_name", this.item.pharmacy_last_name);
          postData.append("pharmacy_mobile", this.item.pharmacy_mobile);
          postData.append("pharmacy_id", this.item.pharmacy_id);
          // postData.append("page_access", this.item.page_access);
          // postData.append("page_access", this.PageId!=null? this.PageId.toString():null);
          // postData.append("city_id", this.CityId!=null? this.CityId.toString():null);
          ApiService.post(ApiEndPoint.Pharmacy.webUpdatePharmacyDetails, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getPharmacyDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },
    //#endregion

    //#region  add Service item
    addServiceItem() {
      if (this.$refs.holdingFormAddService.validate()) {
        if (this.isAddService) {
          // save

          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.Clinic.webSaveClinicService, {
            clinic_id: this.item.clinic_id,
            clinic_service_name: this.item.clinic_service_name,
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getClinicService();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {
          //update

          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.Clinic.webUpdateClinicService, {
            clinic_id: this.item.clinic_id,
            clinic_service_name: this.item.clinic_service_name,
            
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getClinicService();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },
    //#endregion

    //#region  add Timing item
    addTimingItem() {
      if (this.$refs.holdingFormAddTiming.validate()) {
        if (this.isAddTiming) {
          // save

          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.Clinic.webSaveClinicTiming, {
            clinic_id: this.item.clinic_id,
            clinic_days: this.item.clinic_days,
            clinic_timing: this.item.clinic_timing,
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getClinicTiming();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;
              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {
          //update
          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.Clinic.webUpdateClinicTiming, {
            clinic_id: this.item.clinic_id,
            clinic_days: this.item.clinic_days,
            clinic_timing_id: this.item.clinic_timing_id,
            clinic_timing: this.item.clinic_timing,
          })
            .then((response) => {
              
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getClinicTiming();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },
    //#endregion


    //#region  to close the dialog
    close() {
      this.addEditDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, {});
      }, 300);
      this.addServiceDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, {});
      }, 300);
      this.addTimingDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, {});
      }, 300)
      window.location.reload();
    },
    //#endregion

    //#region  enable disable for clinic active
    async enableDisablePharmacyActive(item) {
        const result = await Global.showConfirmationAlert(
          `Change  ${this.entity} : ${item.pharmacy_full_name} Status`,
          "Are you sure to change the status",
          "warning"
        );
        if (result.isConfirmed) {
          this.isLoaderActive = true;
  
          ApiService.post(
            ApiEndPoint.Pharmacy.webChangePharmacyActive, {
            user_id: item.user_id,
            pharmacy_is_active: item.pharmacy_is_active,
          }
          )
            .then((response) => {
              this.isLoaderActive = false;
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getPharmacyDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })  
            .catch((error) => {
              this.isLoaderActive = false;
  
              if (error.response.status != 401 || error.response.status != 403) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {
          if (item.pharmacy_is_active == false) {
            item.pharmacy_is_active = true;
          } else {
            item.pharmacy_is_active = false;
          }
        }
      },
    //#endregion

    //#region  enable disable for Master Clinic
   
    //#endregion
    async deleteItem(item){
     
      const result = await Global.showConfirmationAlert(
        `Delete ${this.entity} ${item.pharmacy_full_name}`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
  
        this.isLoaderActive = true;
        ApiService.post(ApiEndPoint.Pharmacy.deletePharmacyDetails, {
          pharmacy_id: item.pharmacy_id,
        })
          .then((response) => {
            this.isLoaderActive = false;
            if (response.data.success == "true") {
              Global.showSuccessAlert(true, "success", response.data.message);
              this.getPharmacyDetailsList();
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
            window.location.reload();
          })
          .catch((error) => {
            this.isLoaderActive = false;
  
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    }
  },
  
  //#endregion
};
