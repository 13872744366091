import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";

export const healthwhatwedolanguage = {
  props: ["userPermissionDataProps"],
  mixins: [globalMixin],
  data() {
    return {
      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Profile Image",
          value: "image",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
          text: "Title",
          value: "title_name",
          width: "50%",
          sortable: true,
          align: "start",
        },
        {
          text: "Video Url",
          value: "video_url",
          width: "50%",
          sortable: true,
          align: "start",
        },
        {
          text: "Descptions",
          value: "descptions",
          width: "50%",
          sortable: true,
          align: "start",
        },
        {
          text: "Status",
          value: "what_is_active",
          sortable: false,
          width: "20%",
          align: "start",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "25%",
          align: "end",
        },
        // {
        //   text: "Add Video",
        //   value: "addvideo",
        //   sortable: false,
        //   width: "40%",
        //   align: "end",
        // },
      ],

      pagination: {},
      entity: "What We Do",
      // search
      searchText: "",
      // add edit
      defaultItem: {},
      payLoad: {},
      totalItemsInDB: 0,
      item: {},
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Update Data",
      addEditText: "Add",
      isLoaderActive: false,
      isDialogLoaderActive: false,
      doctorProfileImage: null,
      healthBookUrl: null,
      tableItems: [],
      languageItems: [],
      tableDataLoading: false,
      language:['lang_name'],
      //end

      //excel
      excelFields: {
        ID: "product_mode_id",
        Name: "product_mode",
      },
      excelFileName:
        //  "CityMaster" + "_" + moment().format("DD/MM/YYYY") + ".xls",
        "CityMaster" + "_aaaa.xls",
      //end
    };
  },
  created() {
    this.healthBookUrl = Global.healthBookUrl;
    var searchParams = new URLSearchParams(window.location.search);
    this.language_ids = searchParams.get('language_id');
    this.getDetails();
  },
  //#region loading City, Area on page load/ mount 
    mounted() {
      // Show Add in dialog
      this.getLanguage();
      let urlParams = new URLSearchParams(window.location.search);
      console.log(urlParams.has('language_id')); // true
    },
    //#endregion
  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  watch: {
    addEditDialog(value) {
      return value ? true : this.close();
    },
    pagination: {
      handler() {
        this.getDetails();
      },
      deep: true,
    },
  },
  methods: {
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getDetails();
      }, 500);
    },
    close() {
      this.addEditDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, this.defaultItem);
      }, 300);
      window.location.reload();
    },
    // add edit
    // addEditItem() {
    //   if (this.$refs.holdingFormAddEdit.validate()) {
    //     if (this.isAddEdit) {
    //       // save
    //       this.apiCallPost(ApiEndPoint.HealthWhatWeDoVideo.savHealthWhatWeDoVideo, {
    //         title_name: this.item.title_name,
            
    //       });
    //       this.close();
    //     } else {
    //       this.apiCallPost(ApiEndPoint.HealthWhatWeDoVideo.updatHealthWhatWeDoVideo, {
    //         title_name: this.item.title_name,
    //         Id: this.item.healthwhat_id,
    //       });

    //       this.close();
    //     }
    //   }
    // },
    addEditItem() {

      if (this.$refs.holdingFormAddEdit.validate()) {
        if (this.isAddEdit) {
          // save
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          if (this.doctorProfileImage != null) {
            postData.append("profile_image", this.doctorProfileImage);
          }
          postData.append("health_language", this.language_ids);
          postData.append("title_name", this.item.title_name);
          postData.append("video_url", this.item.video_url);
          postData.append("descptions", this.item.descptions);
          
          
          ApiService.post(ApiEndPoint.HealthWhatWeDoVideo.savHealthWhatWeDoVideo, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {

          //update
          this.isDialogLoaderActive = true;
          let postData = new FormData();
           if (this.doctorProfileImage != null) {
            postData.append("profile_image", this.doctorProfileImage);
          }
          postData.append("title_name", this.item.title_name);
          postData.append("video_url", this.item.video_url);
          postData.append("descptions", this.item.descptions);
        
            postData.append("Id", this.item.healthwhat_id);
          ApiService.post(ApiEndPoint.HealthWhatWeDoVideo.updatHealthWhatWeDoVideo, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },


    addLanguage(){ 
      var newurl = 'https://admin.gbsc.co.in/home/healthbook/whatwedo/HealthWhatWeDoLanguage?language_id='+this.item.health_language.toString();
      window.location.replace(newurl);
    },
    addVideo(item){
      this.$router.push({
        name: "HealthWhatWeDo",
        query: {
          healthwhat_id: item.healthwhat_id,
        },
      });
    },
    //show add edit dialog
    showAddEditDialog(item) {
      if (item == null && this.isAddEdit == true) {
        this.addEditText = `Add New ${this.entity}`;
        this.addEditDialog = true;
        this.addUpdateButtonText = " Add ";
        this.getLanguage();
      } else {
        this.item = Object.assign({}, item);
        this.addEditText = `Edit ${this.entity} : `;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
        this.getLanguage();
      }
    },
    // enable disable
    async enableDisableItem(item) {
      console.log(item);
      const result = await Global.showConfirmationAlert(
        `Change Status`,
        "Are you sure to change the status",
        "warning"
      );
      if (result.isConfirmed) {
        this.apiCallPost(ApiEndPoint.HealthWhatWeDoVideo.changHealthWhatWeDoVideoStatus, {
          Id: item.healthwhat_id,
          what_is_active: item.what_is_active,
        });
      } else {
        if (item.what_is_active == false) {
          item.what_is_active = true;
        } else {
          item.what_is_active = false;
        }
      }
    },
     //#region  to load City
     getLanguage() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Language.getLanguage,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.languageItems = response.data.resultData;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion
    // #region Get Details
    getDetails() {
      console.log("Entry");

      this.isLoaderActive = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "desc" : "asc";
      sortBy = sortBy.length == 0 ? `what_is_active` : sortBy[0];
      ApiService.get(ApiEndPoint.HealthWhatWeDoVideo.getHealthWhatWeDoVideo, {
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
        language_ids: this.language_ids
      })
        .then((response) => {
          this.isLoaderActive = false;
          console.log("City", response.data.resultData.data);
          this.language = response.data.getlanguage;
          console.log(this.language);
          this.tableItems = response.data.resultData.data;
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          this.isLoaderActive = false;
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
   



    // delete
    async deleteItem(item) {
      const result = await Global.showConfirmationAlert(
        `Delete Specialization ${item.exersice_title}`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
        this.apiCallPost(ApiEndPoint.HealthWhatWeDoVideo.deletHealthWhatWeDoVideo, {
          healthwhat_id: item.healthwhat_id,
        });
      }
    },

    apiCallPost(endPoint, parameter) {
      ApiService.post(endPoint, parameter)
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.success == "true") {
            Global.showSuccessAlert(true, "success", response.data.message);
            this.getDetails();
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          console.log(error);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    // #endregion
  },
};
