<template>
  <!-- Card Start -->
  <v-container fluid class="pa-4">
    <v-overlay :value="isLoaderActive" color="primary">
      <v-progress-circular
        indeterminate
        size="50"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-card class="mb-10">
      <v-row class="ml-4 mr-4 mt-1 mb-4">
        <v-toolbar-title dark color="primary">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h5">
                <strong>Dashboard</strong>
              </v-list-item-title>
              <v-list-item-subtitle
                >Home <v-icon>mdi-chevron-right</v-icon> Dashboard
                </v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        
      </v-row>
      <v-row class="mx-auto d-flex ml-4">
        <v-col cols="4" md="3" class="pt-5">
          <v-menu
            ref="menu_month_name"
            v-model="menu_month_name"
            :close-on-content-click="false"
            :return-value.sync="month_name"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="month_name"
                label="Select Month"
                prepend-inner-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="month_name"
              no-title
              scrollable
              type="month"
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="menu_month_name = false"
              >
                Cancel
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.menu_month_name.save(month_name)
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        
        <v-col cols="4" md="4" class="pt-5">
          <v-btn
            class="ma-0 primary"
            outlined
            color="white"
            
            medium
            @click="getDetails()"
          style="height: 39px;">
            Go
          </v-btn>
        </v-col>

      </v-row>

      <v-row class="mx-auto d-flex">
        <v-col cols="12" md="6" class="pt-0">
          <v-row class="ml-4 mr-4 mt-1 mb-4">
            <v-col sm="4" md="4">
                <v-card color="success"  class="py-4 text-center white--text mx-auto">
                  <strong>Total OPD 
                  <div v-text="totalOPD" style="font-size:40px;">{{totalOPD}}</div>
                  </strong>
                </v-card>
            </v-col>
            <v-col sm="4" md="4">
                  <v-card color="warning"  class="py-4 text-center white--text mx-auto">
                   <strong> Total Video 
                    <div v-text="totalVideo" style="font-size:40px;">{{totalVideo}}</div>
                    </strong>
                  </v-card>
              </v-col>
              <v-col sm="4" md="4">
                  <v-card color="error"  class="py-4 text-center white--text mx-auto">
                   <strong> NO OPD/OT
                    <div style="font-size:40px;">{{totalNoOtOpd}}</div>
                    </strong>
                  </v-card>
              </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="6" class="pt-0">
         <v-row class="mx-auto d-flex">
        <v-card-text class="py-4 px-2">
        <v-form
                  ref="holdingFormDoctor"
                  v-model="isFormDoctorValid"
                  lazy-validation>

            <v-row class="mx-auto d-flex">
              <v-col cols="12" md="12" class="pt-5">
                <v-autocomplete
                  v-model="item.doctor_id"
                  :items="doctorItems"
                  item-text="doctor_full_name"
                  item-value="doctor_id"
                  outlined
                  dense
                  chips
                  small-chips
                  @change="filter_by_doctor(item)"><template #label>
                    Search By Doctor
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row class="mx-auto d-flex">
              <v-col cols="12" md="12" class="pt-2">
                <v-autocomplete
                  v-model="item.clinic_id"
                  :items="clinicItems"
                  item-text="clinic_full_name"
                  item-value="clinic_id"
                  outlined
                  dense
                  chips
                  small-chips
                   @change="filter_by_doctor(item)"
                  ><template #label>
                    Search By Clinic
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>

        </v-form>
       </v-card-text> 
      </v-row>
        </v-col>
      </v-row>

     

      

      <div style="border: #ccc solid 1px;margin:10px 33px;">
        <v-row class="ml-4 mr-4 mt-2 mb-1" style="font-size: larger;padding-left: 14px;font-weight:500;">OPD
        </v-row>
        <v-row class="ml-4 mr-4 mt-1 mb-4" style="font-weight:bold;">
        <v-col sm="4" md="3">
            <v-card color="success"  class="py-4 text-center white--text mx-auto">
             Active Booking
              <div v-text="activeOPD">{{activeOPD}}</div>
            </v-card>
        </v-col>

        <v-col sm="4" md="3">
            <v-card color="success"  class="py-4 text-center white--text mx-auto">
              Completed Booking
              <div v-text="completedOPD">{{completedOPD}}</div>
            </v-card>
        </v-col>
      
         <v-col sm="4" md="3">
            <v-card color="success"  class="py-4 text-center white--text mx-auto">
              Cancel Booking
              <div v-text="cancelOPD">{{cancelOPD}}</div>
            </v-card>
        </v-col>

         <v-col sm="4" md="3">
            <v-card color="success"  class="py-4 text-center white--text mx-auto">
              Absent Booking
              <div v-text="absentOPD">{{absentOPD}}</div>
            </v-card>
        </v-col>
        
      </v-row>

       <v-row class="ml-4 mr-4 mt-1 mb-4" style="font-weight:bold;">
        <v-col sm="4" md="3">
              <v-card color="primary"  class="py-4 text-center white--text mx-auto">
               Brain
                <div v-text="brainType">{{brainType}}</div>
              </v-card>
          </v-col>
        <v-col sm="4" md="3">
              <v-card color="primary"  class="py-4 text-center white--text mx-auto">
               Spine
                <div v-text="spineType">{{spineType}}</div>
              </v-card>
        </v-col>

        <v-col sm="4" md="3">
            <v-card color="primary"  class="py-4 text-center white--text mx-auto">
             Old Patient
              <div v-text="oldPatient">{{oldPatient}}</div>
            </v-card>
        </v-col>
        <v-col sm="4" md="3">
              <v-card color="primary"  class="py-4 text-center white--text mx-auto">
               New Patient
                <div v-text="newPatient">{{newPatient}}</div>
              </v-card>
          </v-col>
      </v-row>

     </div>


     <div class="mt-6" style="border: #ccc solid 1px;margin:10px 33px;">
        <v-row class="ml-4 mr-4 mt-2 mb-1" style="font-size: larger;padding-left: 14px;font-weight:500;">Video Booking
        </v-row>
      <v-row class="ml-4 mr-4 mt-1 mb-4" style="font-weight:bold;">
        <v-col sm="4" md="3">
            <v-card color="warning"  class="py-4 text-center white--text mx-auto">
              Active Video Booking
              <div v-text="activeVideoBooking">{{activeVideoBooking}}</div>
            </v-card>
        </v-col>

         <v-col sm="4" md="3">
            <v-card color="warning"  class="py-4 text-center white--text mx-auto">
              Completed Video Booking
              <div v-text="completedVideoBooking">{{completedVideoBooking}}</div>
            </v-card>
        </v-col>
      
         <v-col sm="4" md="3">
            <v-card color="warning"  class="py-4 text-center white--text mx-auto">
              Cancel Video Booking
              <div v-text="cancelVideoBooking">{{cancelVideoBooking}}</div>
            </v-card>
        </v-col>

         <v-col sm="4" md="3">
            <v-card color="warning"  class="py-4 text-center white--text mx-auto">
              Absent Video Booking
              <div v-text="absentVideoBooking">{{absentVideoBooking}}</div>
            </v-card>
        </v-col>

      </v-row>
      </div>

      <v-row class="ml-4 mr-4 mt-2 mb-1">&nbsp;
        </v-row>

      <transition name="fade" mode="out-in">
        <template>
        VIew
        </template>
      </transition>
     
      <!-- Card End -->
    </v-card>
  </v-container>
</template>
<script>
import { dashboard } from "../dashboard/dashboard";
export default dashboard;
</script>

