import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
export const icu = {
  props: ["userPermissionDataProps"],
  mixins: [globalMixin],
  //#region  Data section
  data() {
    return {
      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        // {
        //   text: "ICU number",
        //   value: "icu_number",
        //   width: "25%",
        //   sortable: true,
        //   align: "start",
        // },
        {
          text: "Title",
          value: "icu_title",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
            text: "Mobile",
            value: "cug_mobile_no",
            width: "15%",
            sortable: true,
            align: "start",
          },
          {
            text: "Type",
            value: "type",
            width: "15%",
            sortable: true,
            align: "start",
          },
        {
            text: "Status",
            value: "is_active",
            sortable: false,
            width: "15%",
            align: "start",
        },
        
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "30%",
          align: "end",
        },

      ],
      type: ['ICU', 'Ward'],
      pagination: {},
      entity: "ICU",
      searchText: "", // search
      totalItemsInDB: 0,
      item: {},
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Add ICU",
      addEditText: "Add",
      isLoaderActive: false,
      isDialogLoaderActive: false,

      tableItems: [],
      cityItems: [],
      areaItems: [],
      diseaseCategoryItems: [],
      imageRule: [],
      doctorProfileImage: true,
      profileImageUrl: null,
      tableDataLoading: false,

      //end

      //excel
      excelFields: {
        icu_full_name: "icu_full_name",
       
        
      },
      excelFileName:
        "ICU" + "_" + moment().format("DD/MM/YYYY") + ".xls",

      //end
    };
  },
  //#endregion
  //#region  created section
  created() {
    //#get Clinic Details List
    this.getICUDetailsList();
    
  },
  //#endregion

  //#region loading City, Area on page load/ mount 
  mounted() {
    // Show Add in dialog
    this.getCity();
    this.getClinicService();
    this.getClinicTiing();

  },
  //#endregion

  //#region Computed section
  computed: {
    //#region  Numbering data table row
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
    //#endregion
  },
  //#endregion
  //#region watch setion
  watch: {
    //#region  add/edit dialog
    addEditDialog(value) {
      return value ? true : this.close();
    },
    //#endregion
    //#region  add/edit dialog
    addServiceDialog(value) {
      return value ? true : this.close();
    },
    //#endregion
    //#region Pagination
    pagination: {
      handler() {
        this.getICUDetailsList();
      },
      deep: true,
    },
    //#endregion
  },
  //#endregion

  //#region Method section
  methods: {
    checkKeyDownAlphaNumeric(event) {
      if (!/[a-zA-Z\s]/.test(event.key)) {
        this.ignoredValue = event.key ? event.key : "";
        event.preventDefault();
      }
    },
    
    //#region To get the Clinic Details
    getICUDetailsList() {
      this.tableDataLoading = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "desc" : "asc";
      sortBy = sortBy.length == 0 ? `icu_id` : sortBy[0];
      ApiService.get(ApiEndPoint.Icu.webGetIcuDetails, {
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
      })
        .then((response) => {
          this.tableDataLoading = false;

          this.tableItems = response.data.resultData.data;
          console.log(response);
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          this.tableDataLoading = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region To get the Clinic Service
    getClinicService() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Clinic.webGetClinicService,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          console.log(response);
          this.clinicServiceItems = response.data.resultData;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });


    },
    //#endregion

    //#region To get the Clinic Timing
    getClinicTiing() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Clinic.webGetClinicTiming,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          console.log(response);
          this.clinicTimingItems = response.data.resultData;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });


    },
    //#endregion

    //#region  search
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getICUDetailsList();
      }, 500);
    },

    //#endregion

    //#region  show add/edit dialog
    showAddEditDialog(item) {

      if (item == null && this.isAddEdit == true) {
        this.addEditText = `Add  ${this.entity}`;
        this.addEditDialog = true;
        this.addUpdateButtonText = " Add ";
      } else {
        if (item.staff_profile_image != null) {
          let url = item.staff_profile_image
          let lastIndex= url.lastIndexOf('/');
          let data =url.substring(lastIndex+1);
          console.log(data);

        this.item = Object.assign({data}, item);
        this.addEditText = `Edit ${this.entity} : ` + item.icu_full_name;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
        }else{

          // this.getCity();
          // this.getArea();
          
          this.item = Object.assign({}, item);
          this.addEditText = `Edit ${this.entity} : ` + item.icu_full_name;
          this.addEditDialog = true;
          this.addUpdateButtonText = "Update";
        }
      }
    },
    //#endregion

    //#region  show add Service dialog
    showAddServiceDialog(item) {
      if (item == null && this.isAddService == true) {
        this.addServiceText = `Add  ${this.entity}`;
        this.addServiceDialog = true;
        this.addUpdateButtonText = " Add ";
      } else {
        this.item = Object.assign({}, item);
        this.addServiceText = `Edit ${this.addServiceText} : ` + item.clinic_full_name;
        this.addServiceDialog = true;
        this.addUpdateButtonText = "Update";
        this.getClinicService();
      }
    },
    //#endregion

    //#region  show add Timing dialog
    showAddTimingDialog(item) {
      
      if (item == null && this.isAddTiming == true) {
        this.addTimingText = `Add  ${this.entity}`;
        this.addTimingDialog = true;
        this.addUpdateButtonText = " Add ";
      } else {
        
        this.item = Object.assign({}, item);
        this.addTimingText = `Edit ${this.addTimingText} : ` + item.clinic_full_name;
        this.addTimingDialog = true;
        this.addUpdateButtonText = "Update";
        this.getClinicTiming();
      }
    },
    //#endregion

    //#region  to load City
    getCity() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Clinic.getCityIdWithoutPagination,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.cityItems = response.data.resultData;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  to load Area
    async getArea() {
      this.isDialogLoaderActive = true;
      try {
        const response = await ApiService.get(
          ApiEndPoint.Clinic.getAreaIdWithoutPagination,
          { city_id: this.item.city_id.toString() }
        )
        this.areaItems = response.data.resultData;
        this.isDialogLoaderActive = false;
      } catch (error) {
        this.isDialogLoaderActive = false;
        if (error.response.status != 401 && error.response.status != 403) {
          this.showErrorAlert(true, "error", "Something went wrong");
        }
      }
    },
    //#endregion

    //#region  add/edit item
    addEditItem() {

      if (this.$refs.holdingFormAddEdit.validate()) {
        if (this.isAddEdit) {
          // save
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          
          postData.append("icu_number", this.item.icu_number);
          postData.append("icu_title", this.item.icu_title);
          postData.append("cug_mobile_no", this.item.cug_mobile_no);
          postData.append("icu_full_name", this.item.icu_full_name);
          postData.append("type", this.item.type);
          ApiService.post(ApiEndPoint.Icu.webSaveIcuDetails, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getICUDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {

          //update
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          postData.append("icu_number", this.item.icu_number);
          postData.append("icu_title", this.item.icu_title);
          postData.append("cug_mobile_no", this.item.cug_mobile_no);
          postData.append("icu_full_name", this.item.icu_full_name);
          postData.append("icu_id", this.item.icu_id);
          postData.append("type", this.item.type);

          ApiService.post(ApiEndPoint.Icu.webUpdateIcuDetails, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDoctorDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },
    //#endregion

    //#region  add Service item
    addServiceItem() {
      if (this.$refs.holdingFormAddService.validate()) {
        if (this.isAddService) {
          // save

          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.Clinic.webSaveClinicService, {
            clinic_id: this.item.clinic_id,
            clinic_service_name: this.item.clinic_service_name,
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getClinicService();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {
          //update

          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.Clinic.webUpdateClinicService, {
            clinic_id: this.item.clinic_id,
            clinic_service_name: this.item.clinic_service_name,
            
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getClinicService();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },
    //#endregion

    //#region  add Timing item
    addTimingItem() {
      if (this.$refs.holdingFormAddTiming.validate()) {
        if (this.isAddTiming) {
          // save

          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.Clinic.webSaveClinicTiming, {
            clinic_id: this.item.clinic_id,
            clinic_days: this.item.clinic_days,
            clinic_timing: this.item.clinic_timing,
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getClinicTiming();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;
              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {
          //update
          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.Clinic.webUpdateClinicTiming, {
            clinic_id: this.item.clinic_id,
            clinic_days: this.item.clinic_days,
            clinic_timing_id: this.item.clinic_timing_id,
            clinic_timing: this.item.clinic_timing,
          })
            .then((response) => {
              
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getClinicTiming();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },
    //#endregion


    //#region  to close the dialog
    close() {
      this.addEditDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, {});
      }, 300);
      this.addServiceDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, {});
      }, 300);
      this.addTimingDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, {});
      }, 300)
      window.location.reload();
    },
    //#endregion

    //#region  enable disable for clinic active
    async enableDisableIcuActive(item) {
        const result = await Global.showConfirmationAlert(
          `Change  ${this.entity} : ${item.icu_full_name} Status`,
          "Are you sure to change the status",
          "warning"
        );
        if (result.isConfirmed) {
          this.isLoaderActive = true;
  
          ApiService.post(
            ApiEndPoint.Icu.webChangeIcuActive, {
            icu_id: item.icu_id,
            is_active: item.is_active,
          }
          )
            .then((response) => {
              this.isLoaderActive = false;
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDoctorDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isLoaderActive = false;
  
              if (error.response.status != 401 || error.response.status != 403) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {
          if (item.is_active == false) {
            item.is_active = true;
          } else {
            item.is_active = false;
          }
        }
      },
    //#endregion

    //#region  enable disable for Master Clinic
   
    //#endregion
    async deleteItem(item){
     
      const result = await Global.showConfirmationAlert(
        `Delete ${this.entity} ${item.icu_full_name}`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
  
        this.isLoaderActive = true;
        ApiService.post(ApiEndPoint.Icu.deleteIcuDetails, {
          icu_id: item.icu_id,
        })
          .then((response) => {
            this.isLoaderActive = false;
            if (response.data.success == "true") {
              Global.showSuccessAlert(true, "success", response.data.message);
              this.getICUDetailsList();
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;
  
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    }
  },  
  //#endregion
};
