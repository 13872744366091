import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
export const juniordoctor = {
  props: ["userPermissionDataProps","addsubDoctorDataProps","addchataccessDataProps"],
  mixins: [globalMixin],
  //#region  Data section
  data() {
    return {       
      PageId:
      this.addsubDoctorDataProps != null 
        ? this.addsubDoctorDataProps.item.page_access.split(",")
        : null,

      ChatId:
      this.addchataccessDataProps != null 
        ? this.addchataccessDataProps.item.chat_access.split(",")
        : null,
      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Device Profile Image",
          value: "image",
          width: "15%",
          sortable: false,
          align: "start",
        },
        {
          text: "Name",
          value: "full_name",
          width: "25%",
          sortable: true,
          align: "start",
        },
        {
          text: "Mobile",
          value: "mobile",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
          text: "Type",
          value: "type",
          width: "15%",
          sortable: true,
          align: "start",
        },
       
        {
            text: "Status",
            value: "is_active",
            sortable: false,
            width: "15%",
            align: "start",
        },
        
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "30%",
          align: "end",
        },
       
      ],
      type: ['Junior Doctor', 'Co-Ordinator'],
      pagination: {},
      entity: "Junior Doctor & Coordinator",
      searchText: "", // search
      totalItemsInDB: 0,
      pageAccess: ['DashboardModule','VideoConsultancy','ClinicAppointmemnt','IpModule'],
      chatAccess: ['MRI','ChatWithUs','GetMedicine'],
      item: {},
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Add Junior Doctor & Coordinator",
      addEditText: "Add",
      isLoaderActive: false,
      isDialogLoaderActive: false,
      // doctorName:[],
      tableItems: [],
      cityItems: [],
      areaItems: [],
      diseaseCategoryItems: [],
      imageRule: [],
      doctorProfileImage: null,
      editdoctorProfileImage:true,
      NursingUrl: null,
      tableDataLoading: false,

      //end

      //excel
      excelFields: {
        full_name: "full_name",
       
        
      },
      excelFileName:
        "JuniorDoctor&Coordinator" + "_" + moment().format("DD/MM/YYYY") + ".xls",

      //end
    };
  },
  //#endregion
  //#region  created section
  created() {
   this.getDoctorName();
      // this.selected = data[0];
      // this.items = data;
   
    //#get Clinic Details List
    //this.doctorName = await this.getAll("getWithoutPaginationCity", {});
    this.getjuniordoctorDetailsList();
    
  },
  //#endregion

  //#region loading City, Area on page load/ mount 
  mounted() {
    // Show Add in dialog
    this.getCity();
    this.getClinicService();
    this.getClinicTiing();
    // this.getDoctorName();

  },
  //#endregion

  //#region Computed section
  computed: {
    //#region  Numbering data table row
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
    //#endregion
  },
  //#endregion
  //#region watch setion
  watch: {
    //#region  add/edit dialog
    addEditDialog(value) {
      return value ? true : this.close();
    },
    //#endregion
    //#region  add/edit dialog
       //#endregion
    //#region Pagination
    pagination: {
      handler() {
        this.getjuniordoctorDetailsList();
      },
      deep: true,
    },
    //#endregion
  },
  //#endregion

  //#region Method section
  methods: {
    checkKeyDownAlphaNumeric(event) {
      console.log(event.key);
      if (!/[a-zA-Z]/.test(event.key)) {
        this.ignoredValue = event.key ? event.key : "";
        event.preventDefault();
      }
    },
    currentDataItems(event) {
        console.log(event);
        // event.preventDefault();
        // return this.portals.filter(val => val.type == (item.type)); // this.portals.filter(val => val.chapterId === '1')
        
      },

    //#region To get the Clinic Details
    getjuniordoctorDetailsList() {
      this.tableDataLoading = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "desc" : "asc";
      sortBy = sortBy.length == 0 ? `id` : sortBy[0];
      ApiService.get(ApiEndPoint.juniorDoctor.webGetjuniorDoctorDetails, {
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
      })
        .then((response) => {
          this.tableDataLoading = false;

          this.tableItems = response.data.resultData.data;
          console.log(response);
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          this.tableDataLoading = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region To get the Clinic Service
    getClinicService() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Clinic.webGetClinicService,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          console.log(response);
          this.clinicServiceItems = response.data.resultData;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });


    },
    //#endregion

    //#region To get the Clinic Timing
    getClinicTiing() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Clinic.webGetClinicTiming,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          console.log(response);
          this.clinicTimingItems = response.data.resultData;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });


    },
    //#endregion

    //#region  search
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getjuniordoctorDetailsList();
      }, 500);
    },

    //#endregion

    //#region  show add/edit dialog
    showAddEditDialog(item) {

      if (item == null && this.isAddEdit == true) {
        this.addEditText = `Add  ${this.entity}`;
        this.addEditDialog = true;
        this.addUpdateButtonText = " Add ";
        this.PageId = null;
        this.ChatId = null;
      } else {
        if (item.image != null) {
          let url = item.image
          let lastIndex= url.lastIndexOf('/');
          let data =url.substring(lastIndex+1);
          console.log(data);

        this.item = Object.assign({data}, item);
        this.addEditText = `Edit ${this.entity} : ` + item.full_name;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
        this.PageId=this.item != null  ? this.item.page_access.split(",") : null;
        this.ChatId=this.item != null  ? this.item.chat_access.split(",") : null;
        // this.selected = this.item.doctor_id;
        // console.log( this.selected +' '+this.item.doctor_id);
        }else{


          // this.getCity();
          // this.getArea();
          
          this.item = Object.assign({}, item);
          this.addEditText = `Edit ${this.entity} : ` + item.full_name;
          this.addEditDialog = true;
          this.addUpdateButtonText = "Update";
          this.PageId=this.item != null  ? this.item.page_access.split(",") : null;
          this.ChatId=this.item != null  ? this.item.chat_access.split(",") : null;
          // this.selected = this.item.doctor_id
          // console.log( this.selected +' '+this.item.doctor_id);
        }
      }
    },
    //#endregion

    //#region  show add Service dialog

    //#endregion

    //#region  show add Timing dialog
    showAddTimingDialog(item) {
      
      if (item == null && this.isAddTiming == true) {
        this.addTimingText = `Add  ${this.entity}`;
        this.addTimingDialog = true;
        this.addUpdateButtonText = " Add ";

      } else {
        
        this.item = Object.assign({}, item);
        this.addTimingText = `Edit ${this.addTimingText} : ` + item.clinic_full_name;
        this.addTimingDialog = true;
        this.addUpdateButtonText = "Update";
        this.getClinicTiming();
    
      }
    },
    //#endregion

    //#region  to load City
    getCity() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Clinic.getCityIdWithoutPagination,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.cityItems = response.data.resultData;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  to getDoctor Name
    // async getDoctorName() {
    //   this.isDialogLoaderActive = true;
    //   try {
    //     const response = await ApiService.get(
    //       ApiEndPoint.juniorDoctor.getWithoutPaginationDoctor,
    //       {}
    //     )
    //     this.isDialogLoaderActive = false;
    //     this.doctorNameItems = response.data.resultData;
    //     console.log(this.doctorNameItems);
    //     // this.doctorNameID =this.item != null 
    //     //   ? this.item.doctor_id.split(",").map(Number)
    //     //   : null

    //   } catch (error) {
    //     this.isDialogLoaderActive = false;
    //     if (error.response.status != 401 && error.response.status != 403) {
    //       this.showErrorAlert(true, "error", "Something went wrong");
    //     }
    //   }
    // },
    //#region  to load Area
    async getArea() {
      this.isDialogLoaderActive = true;
      try {
        const response = await ApiService.get(
          ApiEndPoint.Clinic.getAreaIdWithoutPagination,
          { city_id: this.item.city_id.toString() }
        )
        this.areaItems = response.data.resultData;
        this.isDialogLoaderActive = false;
      } catch (error) {
        this.isDialogLoaderActive = false;
        if (error.response.status != 401 && error.response.status != 403) {
          this.showErrorAlert(true, "error", "Something went wrong");
        }
      }
    },
    //#endregion

    //#region  add/edit item
    addEditItem() {

      if (this.$refs.holdingFormAddEdit.validate()) {
        if (this.isAddEdit) {
          // save
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          if (this.doctorProfileImage != null) {
            postData.append("image", this.doctorProfileImage);
          }
          postData.append("first_name", this.item.first_name);
          postData.append("last_name", this.item.last_name);
          postData.append("mobile", this.item.mobile);
          postData.append("type", this.item.type);
          // postData.append("doctor_id", this.selected);
          postData.append("page_access", this.PageId!=null? this.PageId.toString():null);
          postData.append("chat_access", this.ChatId!=null? this.ChatId.toString():null);

          ApiService.post(ApiEndPoint.juniorDoctor.webSavejuniorDoctorDetails, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getjuniordoctorDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {

          //update
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          if (this.editdoctorProfileImage != true) {
            postData.append("image", this.editdoctorProfileImage);
          }
        
          postData.append("first_name", this.item.first_name);
          postData.append("last_name", this.item.last_name);
          postData.append("mobile", this.item.mobile);
          postData.append("type", this.item.type);
          postData.append("id", this.item.id);
          // postData.append("doctor_id", this.selected);
          postData.append("page_access", this.PageId!=null? this.PageId.toString():null);
          postData.append("chat_access", this.ChatId!=null? this.ChatId.toString():null);

          ApiService.post(ApiEndPoint.juniorDoctor.webUpdatejuniorDoctorDetails, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDoctorDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },
    //#endregion

    //#region  add Service item
    addServiceItem() {
      if (this.$refs.holdingFormAddService.validate()) {
        if (this.isAddService) {
          // save

          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.Clinic.webSaveClinicService, {
            clinic_id: this.item.clinic_id,
            clinic_service_name: this.item.clinic_service_name,
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getClinicService();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {
          //update

          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.Clinic.webUpdateClinicService, {
            clinic_id: this.item.clinic_id,
            clinic_service_name: this.item.clinic_service_name,
            
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getClinicService();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },
    //#endregion

    //#region  add Timing item
    addTimingItem() {
      if (this.$refs.holdingFormAddTiming.validate()) {
        if (this.isAddTiming) {
          // save

          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.Clinic.webSaveClinicTiming, {
            clinic_id: this.item.clinic_id,
            clinic_days: this.item.clinic_days,
            clinic_timing: this.item.clinic_timing,
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getClinicTiming();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;
              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {
          //update
          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.Clinic.webUpdateClinicTiming, {
            clinic_id: this.item.clinic_id,
            clinic_days: this.item.clinic_days,
            clinic_timing_id: this.item.clinic_timing_id,
            clinic_timing: this.item.clinic_timing,
          })
            .then((response) => {
              
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getClinicTiming();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },
    //#endregion


    //#region  to close the dialog
    close() {
      this.addEditDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, {});
      }, 300);
    
       window.location.reload();
      this.addTimingDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, {});
      }, 300)
      // window.location.reload();
    },
    //#endregion

    //#region  enable disable for clinic active
    async enableDisablejuniorDoctorActive(item) {
        const result = await Global.showConfirmationAlert(
          `Change  ${this.entity} : ${item.full_name} Status`,
          "Are you sure to change the status",
          "warning"
        );
        if (result.isConfirmed) {
          this.isLoaderActive = true;
  
          ApiService.post(
            ApiEndPoint.juniorDoctor.webChangejuniorDoctorActive, {
            user_id: item.user_id,
            is_active: item.is_active,
          }
          )
            .then((response) => {
              console.log(response.data.result);
              this.isLoaderActive = false;
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDoctorDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })  
            .catch((error) => {
              this.isLoaderActive = false;
  
              if (error.response.status != 401 || error.response.status != 403) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {
          if (item.is_active == false) {
            item.is_active = true;
          } else {
            item.is_active = false;
          }
        }
      },
    //#endregion

    //#region  enable disable for Master Clinic
   
    //#endregion
    async deleteItem(item){
     
      const result = await Global.showConfirmationAlert(
        `Delete ${this.entity} ${item.full_name}`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
  
        this.isLoaderActive = true;
        ApiService.post(ApiEndPoint.juniorDoctor.deletejuniorDoctorDetails, {
          id: item.id,
        })
          .then((response) => {
            this.isLoaderActive = false;
            if (response.data.success == "true") {
              Global.showSuccessAlert(true, "success", response.data.message);
              this.getjuniordoctorDetailsList();
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
            window.location.reload();
          })
          .catch((error) => {
            this.isLoaderActive = false;
  
            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    }
  },
  
  //#endregion
};
