import { globalMixin } from "../../mixins/globalMixin";
import { ApiEndPoint } from "../../helpers/apiEndPoint";
import { ApiService } from "@/helpers/apiService";
import { Global } from "@/helpers/global";
export const dashboard = {
  props: ["userPermissionDataProps"],
  mixins: [globalMixin],

  // #region Components
  components: {},
  // #endregion

  // #region Data Section
  data() {
    return {
      DoctorId:
        this.addGroupDataProps != null 
          ? this.addGroupDataProps.item.doctor_id.split(",").map(Number)
          : null,
      isLoaderActive: false,
      isholdingFormValid: true,
      isPasswordVisible: false,

      entity:"Total OPD",
      item: {},
     
      totalOPD: null,
      activeOPD:null,
      absentOPD:null,
      cancelOPD:null,
      completedOPD:null,
      totalVideo: null,
      activeVideoBooking:null,
      cancelVideoBooking:null,
      completedVideoBooking:null,
      absentVideoBooking:null,
      spineType:null,
      brainType:null,
      oldPatient:null,
      newPatient:null,
      totalNoOtOpd:null,
       isFormDoctorValid: false,
       doctorItems: [],
       clinicItems: [],

       menu_month_name: false,
       month_name:moment().format("YYYY-MM"),
                
    };
  },
  // #endregion
  //#region - Function Created
  created() {
    // get the vuetify dark theme false
    this.$vuetify.theme.dark = false;
    this.getOpdBooking();
    this.getVideoBooking();
    this.getDoctor();
    this.getClinic();
    this.getNoOtOpd();
  },
  //#endregion
  //#region Mounted
  mounted() {
    this.getOpdBooking();
    this.getVideoBooking();
    this.getDoctor();
    this.getClinic();
    this.getNoOtOpd();
  },
  //#endregion

  // #region Method Section
  methods: {
    async getOpdBooking() {

      this.isLoaderActive = true;
     
      ApiService.post(ApiEndPoint.Dashboard.getOpdBooking, {
       
      })
        .then((response) => {
          this.isLoaderActive = false;
          console.log(response.data.allBooking);
          this.totalOPD=response.data.allBooking;
          this.activeOPD=response.data.activeBooking;
          this.absentOPD=response.data.absentBooking;
          this.cancelOPD=response.data.cancelBooking;
          this.completedOPD=response.data.completedBooking;
          this.brainType=response.data.brainType;
          this.spineType=response.data.spineType;
          this.oldPatient=response.data.oldPatient;
          this.newPatient=response.data.newPatient;
          
        })
        .catch((error) => {
          this.isLoaderActive = false;
          this.totalOPD='';
        });

    },

    async getVideoBooking() {

      this.isLoaderActive = true;
     
       ApiService.post(ApiEndPoint.Dashboard.getVideoBooking, {
      })
        .then((response) => {
          this.isLoaderActive = false;
          console.log(response.data.resultData2);
          this.totalVideo=response.data.allVideoBooking;
          this.activeVideoBooking=response.data.activeVideoBooking;
          this.cancelVideoBooking=response.data.cancelVideoBooking;
          this.completedVideoBooking=response.data.completedVideoBooking;
          this.absentVideoBooking=response.data.absentVideoBooking;
        })
        .catch((error) => {
          this.isLoaderActive = false;
          //this.totalVideo='';
        });
    },

    //#region  to load Doctor
    async getDoctor() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Dashboard.getDoctorIdWithoutPagination,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.doctorItems = response.data.resultData;
         
           //this.DoctorId=this.item.doctor_id.split(",").map(Number);
          
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  to load Doctor
    async getClinic() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Dashboard.getClinicListWithoutPagination,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.clinicItems = response.data.resultData;
                   
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    async filter_by_doctor(item)
    {
      this.isDialogLoaderActive = true;
      console.log(item.clinic_id);
      console.log(this.month_name);
      ApiService.post(ApiEndPoint.Dashboard.getOpdBooking, {
       doctor_id:item.doctor_id,
       clinic_id:item.clinic_id,
       month_name: this.month_name,
      })
        .then((response) => {
          this.isLoaderActive = false;
          console.log(response.data.allBooking);
          this.totalOPD=response.data.allBooking;
          this.activeOPD=response.data.activeBooking;
          this.absentOPD=response.data.absentBooking;
          this.cancelOPD=response.data.cancelBooking;
          this.completedOPD=response.data.completedBooking;
          this.brainType=response.data.brainType;
          this.spineType=response.data.spineType;
          this.oldPatient=response.data.oldPatient;
          this.newPatient=response.data.newPatient;
          
        })
        .catch((error) => {
          this.isLoaderActive = false;
          this.totalOPD='';
        });


        ApiService.post(ApiEndPoint.Dashboard.getVideoBooking, {
          doctor_id:item.doctor_id,
          month_name: this.month_name,
        })
        .then((response) => {
          this.isLoaderActive = false;
          this.totalVideo=response.data.allVideoBooking;
          this.activeVideoBooking=response.data.activeVideoBooking;
          this.cancelVideoBooking=response.data.cancelVideoBooking;
          this.completedVideoBooking=response.data.completedVideoBooking;
          this.absentVideoBooking=response.data.absentVideoBooking;
          
        })
        .catch((error) => {
          this.isLoaderActive = false;
          this.totalVideo=0;
        });

        ApiService.post(ApiEndPoint.Dashboard.getNoOtOpd, {
          doctor_id:item.doctor_id,
          month_name: this.month_name,
        })
        .then((response) => {
          this.isLoaderActive = false;
          
          this.totalNoOtOpd=response.data.resultData;
          
        })
        .catch((error) => {
          this.isLoaderActive = false;
          this.totalNoOtOpd=0;
        });

    },

    async getNoOtOpd() {

      this.isLoaderActive = true;
     
      ApiService.post(ApiEndPoint.Dashboard.getNoOtOpd, {
       
      })
        .then((response) => {
          this.isLoaderActive = false;
          
          this.totalNoOtOpd=response.data.resultData;
          
          
        })
        .catch((error) => {
          this.isLoaderActive = false;
          this.totalNoOtOpd='';
        });

    },

    async getDetails()
    {
      console.log(this.month_name);
      
      //item.clinic_id,
       console.log(this.item.doctor_id);
       this.isDialogLoaderActive = true;
     

      ApiService.post(ApiEndPoint.Dashboard.getOpdBooking, {
       doctor_id:this.item.doctor_id,
       clinic_id:this.item.clinic_id,
       month_name: this.month_name,
      })
        .then((response) => {
          this.isLoaderActive = false;
          console.log(response.data.allBooking);
          this.totalOPD=response.data.allBooking;
          this.activeOPD=response.data.activeBooking;
          this.absentOPD=response.data.absentBooking;
          this.cancelOPD=response.data.cancelBooking;
          this.completedOPD=response.data.completedBooking;
          this.brainType=response.data.brainType;
          this.spineType=response.data.spineType;
          this.oldPatient=response.data.oldPatient;
          this.newPatient=response.data.newPatient;
          
        })
        .catch((error) => {
          this.isLoaderActive = false;
          this.totalOPD='';
        });


        ApiService.post(ApiEndPoint.Dashboard.getVideoBooking, {
          doctor_id:this.item.doctor_id,
          month_name: this.month_name,
        })
        .then((response) => {
          this.isLoaderActive = false;
          this.totalVideo=response.data.allVideoBooking;
          this.activeVideoBooking=response.data.activeVideoBooking;
          this.cancelVideoBooking=response.data.cancelVideoBooking;
          this.completedVideoBooking=response.data.completedVideoBooking;
          this.absentVideoBooking=response.data.absentVideoBooking;
          
        })
        .catch((error) => {
          this.isLoaderActive = false;
          this.totalVideo=0;
        });

        ApiService.post(ApiEndPoint.Dashboard.getNoOtOpd, {
          doctor_id:this.item.doctor_id,
          month_name: this.month_name,
        })
        .then((response) => {
          this.isLoaderActive = false;
          
          this.totalNoOtOpd=response.data.resultData;
          
        })
        .catch((error) => {
          this.isLoaderActive = false;
          this.totalNoOtOpd=0;
        });

    },
  },

  

  //#endregion
};
