import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
export const doctorMaster = {
  props: ["userPermissionDataProps", "addDoctorDataProps","addchataccessDataProps"],
  mixins: [globalMixin],
  //#region  Data section
  data() {
    return {
      // Data Table

      CityId:
        this.addDoctorDataProps != null 
          ? this.addDoctorDataProps.item.city_id.split(",").map(Number)
          : null,
      AreaId:
        this.addDoctorDataProps != null 
          ? this.addDoctorDataProps.item.area_id.split(",").map(Number)
          : null,
      DiseaseCategoryId:
        this.addDoctorDataProps != null 
          ? this.addDoctorDataProps.item.disease_category_id.split(",").map(Number)
          : null,
      ChatId:
        this.addchataccessDataProps != null 
          ? this.addchataccessDataProps.item.chat_access.split(",")
          : null,
      GetJrDoctor:
        this.addDoctorDataProps != null 
          ? this.addDoctorDataProps.item.jr_doctor_id.split(",").map(Number)
          : null,
      GetCodiDoctor:
        this.addDoctorDataProps != null 
          ? this.addDoctorDataProps.item.coordinator_id.split(",").map(Number)
          : null, 

      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Name",
          value: "doctor_full_name",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
          text: "Device Profile Image",
          value: "doctor_profile_image",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
          text: "Mobile",
          value: "doctor_mobile_number",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
          text: "Specialization",
          value: "specialization_name",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
          text: "Experience",
          value: "doctor_overall_experience",
          width: "10%",
          sortable: true,
          align: "start",
        },
        {
          text: "Status",
          value: "doctor_is_active",
          sortable: false,
          width: "15%",
          align: "start",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "15%",
          align: "end",
        },
      ],

      pagination: {},
      entity: "Doctor",
      searchText: "", // search
      totalItemsInDB: 0,
      item: {},
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Add Doctor",
      addEditText: "Add",
      isLoaderActive: false,
      isDialogLoaderActive: false,
      chatAccess: ['MRI','ChatWithUs','GetMedicine'],
      tableItems: [],
      cityItems: [],
      areaItems: [],
      diseaseCategoryItems: [],
      jrdoctorNameItems:[],
      coordinatorNameItems:[],
      imageRule: [],
      doctorProfileImage: null,
      editdoctorProfileImage: true,
      profileImageUrl: null,
      tableDataLoading: false,

      //end

      //excel
      excelFields: {
        doctor_full_name: "doctor_full_name",
        doctor_profile_image: "doctor_profile_image",
        doctor_mobile_number: "doctor_mobile_number",
        specialization_name: "specialization_name",
        doctor_overall_experience: "doctor_overall_experience",
        Status: "doctor_is_active",
      },
      excelFileName:
        "Doctor" + "_" + moment().format("DD/MM/YYYY") + ".xls",
      //end
    };

  },
  //#endregion

  //#region  created section
  created() {
    //  get the Doctor Details List
    this.profileImageUrl = Global.profileImageUrl;
    this.getDoctorDetailsList();


  },
  //#endregion

  //#region Computed section
  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  //#endregion

  //#region watch setion
  watch: {
    //#region  add/edit dialog
    addEditDialog(value) {
      return value ? true : this.close();
    },
    //#endregion

    //#region 
    // doctorProfileImage(val) {
    //   this.doctorProfileImage = val;
    //   this.imageRule =
    //     this.doctorProfileImage != null
    //       ? [(v) => !v || v.size <= 1048576 || "File size should be 1MB"]
    //       : [];
    // },
    //#endregion

    //#region Pagination
    pagination: {
      handler() {
        this.getDoctorDetailsList();
      },
      deep: true,
    },
    //#endregion
  },
  //#endregion


  //#region loading City, Area on page load/ mount 
  mounted() {
    // Show Add in dialog
    this.getCity();
    this.getDiseaseCategory();
    this.getJrDoctorlist();
    this.getCodiDoctorlist();
  },
  //#endregion

  //#region Method section
  methods: {
    //#region To get the Doctor Details List
    getDoctorDetailsList() {
      this.tableDataLoading = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "desc" : "asc";
      sortBy = sortBy.length == 0 ? `doctor_id` : sortBy[0];
      ApiService.get(ApiEndPoint.Doctor.webGetDoctorDetails, {
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
      })
        .then((response) => {
          this.tableDataLoading = false;
          this.tableItems = response.data.resultData.data;
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          this.tableDataLoading = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  search
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getDoctorDetailsList();
      }, 500);
    },
    //#endregion

    //#region  show add/edit dialog
    async showAddEditDialog(item) {

      // Show Add
      if (item == null && this.isAddEdit == true) {
        this.addEditText = `Add ${this.entity}`;
        this.addEditDialog = true;
        this.addUpdateButtonText = " Add ";
        this.CityId = null;
        this.AreaId = null;
        this.DiseaseCategoryId = null;
        this.ChatId = null;
        this.GetJrDoctor=null;
        this.GetCodiDoctor=null;
      } else {
        if (item.doctor_profile_image != null) {
          let url = item.doctor_profile_image
          let lastIndex= url.lastIndexOf('/');
          let data =url.substring(lastIndex+1);
          console.log(data);

        this.item = Object.assign({data}, item);
        this.addEditText = `Edit ${this.entity} : ` + item.doctor_full_name;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
        this.getCity();
        // this.getArea();
        this.getAreas();
        this.getDiseaseCategory();
        this.ChatId=this.item != null  ? this.item.chat_access.split(",") : null;
        this.GetJrDoctor=this.item.jr_doctor_id;
        this.GetCodiDoctor=this.item.coordinator_id;
        console.log(this.GetCodiDoctor);
        }else{
        this.item = Object.assign({}, item);
        this.addEditText = `Edit ${this.entity} : ` + item.doctor_full_name;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
        this.getCity();
        // this.getArea();
        this.getAreas();
        this.getDiseaseCategory();
        this.ChatId=this.item != null  ? this.item.chat_access.split(",") : null;
        this.GetJrDoctor=this.item.jr_doctor_id;
        this.GetCodiDoctor=this.item.coordinator_id;
        }

        // Show Edit (Update)
        // this.item = Object.assign({}, item);
        // this.addEditText = `Edit ${this.entity} : ` + item.doctor_full_name;
        // this.addEditDialog = true;
        // this.addUpdateButtonText = "Update";
        // this.getCity();
        // this.getArea();
        // this.getDiseaseCategory();
      }
    },
    //#endregion

    //#region  to load City
    getCity() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Doctor.getCityIdWithoutPagination,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.cityItems = response.data.resultData;
          this.CityId=this.item != null  ? this.item.city_id.split(",").map(Number) : null;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  to load Get JrDoctor
    getJrDoctorlist() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Doctor.GetWithoutPaginationJuniorDoctor,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.jrdoctorNameItems = response.data.resultData;

          this.GetJrDoctor=this.item != null  ? this.item.jr_doctor_id.split(",").map(Number) : null;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  to load Get CodiDoctor
    getCodiDoctorlist() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.Doctor.GetWithoutPaginationCoordinator,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.coordinatorNameItems = response.data.resultData;

          this.GetCodiDoctor=this.item != null  ? this.item.coordinator_id.split(",").map(Number) : null;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  to load Area
    async getArea() {
      this.isDialogLoaderActive = true;
      try {

        // this.CityId = this.item != null ? this.item.city_id.split(",").map(Number) : null

        const response = await ApiService.get(
          ApiEndPoint.Doctor.getAreaIdWithoutPagination,
          {city_id: this.CityId!=null? this.CityId.toString():null}
        )
        this.isDialogLoaderActive = false;
        this.areaItems = response.data.resultData;
        this.AreaId=this.item != null  ? this.item.area_id.split(",").map(Number) : null;

      } catch (error) {
        this.isDialogLoaderActive = false;
        if (error.response.status != 401 && error.response.status != 403) {
          this.showErrorAlert(true, "error", "Something went wrong");
        }
      }
    },

    async getAreas() {
      this.isDialogLoaderActive = true;
      try {

        // this.CityId = this.item != null ? this.item.city_id.split(",").map(Number) : null

        const response = await ApiService.get(
          ApiEndPoint.Doctor.getAreaIdWithoutPaginations,
          {}
        )
        this.isDialogLoaderActive = false;

        this.areaItems = response.data.resultData;
        
        this.AreaIds=this.item != null  ? this.item.area_id.split(",").map(Number) : null;

      } catch (error) {
        this.isDialogLoaderActive = false;
        if (error.response.status != 401 && error.response.status != 403) {
          this.showErrorAlert(true, "error", "Something went wrong");
        }
      }
    },
    //#endregion

    //#region  to load City
    async getDiseaseCategory() {
      this.isDialogLoaderActive = true;
      try {
        const response = await ApiService.get(
          ApiEndPoint.Doctor.webGetDiseaseCategory,
          {}
        )
        this.isDialogLoaderActive = false;
        this.diseaseCategoryItems = response.data.resultData;
        
        this.DiseaseCategoryId=this.item != null 
          ? this.item.disease_category_id.split(",").map(Number)
          : null

      } catch (error) {
        this.isDialogLoaderActive = false;
        if (error.response.status != 401 && error.response.status != 403) {
          this.showErrorAlert(true, "error", "Something went wrong");
        }
      }
    },
    //#endregion

    //#region  add/edit item
    addEditItem() {

      if (this.$refs.holdingFormAddEdit.validate()) {
        if (this.isAddEdit) {
          // save
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          if (this.doctorProfileImage != null) {
            postData.append("doctor_profile_image", this.doctorProfileImage);
          }
          postData.append("CityId", this.CityId!=null? this.CityId.toString():null);
          postData.append("AreaId", this.AreaIds!=null? this.AreaIds.toString():null);
          postData.append("DiseaseCategoryId", this.DiseaseCategoryId!=null? this.DiseaseCategoryId.toString():null);


          postData.append("doctor_overall_experience", this.item.doctor_overall_experience);
          postData.append("doctor_first_name", this.item.doctor_first_name);
          postData.append("doctor_last_name", this.item.doctor_last_name);
          postData.append("specialization_name", this.item.specialization_name);
          postData.append("education_name", this.item.education_name);
          postData.append("doctor_mobile_number", this.item.doctor_mobile_number);
          postData.append("doctor_description", this.item.doctor_description);
          postData.append("experience_name", this.item.experience_name);
          postData.append("chat_access", this.ChatId!=null? this.ChatId.toString():null);
          postData.append("jr_doctor_id", this.GetJrDoctor!=null? this.GetJrDoctor.toString():null);
          postData.append("coordinator_id", this.GetCodiDoctor!=null? this.GetCodiDoctor.toString():null);

          ApiService.post(ApiEndPoint.Doctor.saveDoctorDetails, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDoctorDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {

          //update
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          if (this.editdoctorProfileImage != true) {
            postData.append("doctor_profile_image", this.editdoctorProfileImage);
          }
          postData.append("city_id", this.CityId!=null? this.CityId.toString():null);
          postData.append("AreaId", this.AreaIds!=null? this.AreaIds.toString():null);
          postData.append("DiseaseCategoryId", this.DiseaseCategoryId!=null? this.DiseaseCategoryId.toString():null);

          postData.append("doctor_overall_experience", this.item.doctor_overall_experience);
          postData.append("doctor_first_name", this.item.doctor_first_name);
          postData.append("doctor_last_name", this.item.doctor_last_name);
          postData.append("specialization_name", this.item.specialization_name);
          postData.append("education_name", this.item.education_name);
          postData.append("doctor_mobile_number", this.item.doctor_mobile_number);
          postData.append("doctor_description", this.item.doctor_description);
          postData.append("experience_name", this.item.experience_name);
          postData.append("doctor_id", this.item.doctor_id);
          postData.append("chat_access", this.ChatId!=null? this.ChatId.toString():null);
          postData.append("jr_doctor_id", this.GetJrDoctor!=null? this.GetJrDoctor.toString():null);
          postData.append("coordinator_id", this.GetCodiDoctor!=null? this.GetCodiDoctor.toString():null);
          
          ApiService.post(ApiEndPoint.Doctor.webUpdateDoctorDetails, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDoctorDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },

    //#endregion

    //#region  to close the dialog
    close() {
      this.addEditDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, {
          cityItems: this.cityItems,
          areaItems: this.areaItems,
        });
      }, 300);
      window.location.reload();
    },
    //#endregion

    //#region  enable disable
    async enableDisableItem(item) {
      const result = await Global.showConfirmationAlert(
        `Change  ${this.entity} : ${item.doctor_full_name} Status`,
        "Are you sure to change the status",
        "warning"
      );
      if (result.isConfirmed) {
        this.isLoaderActive = true;

        ApiService.post(
          ApiEndPoint.Doctor.changeDoctorDetailsStatus, {
          doctor_id: item.doctor_id,
          doctor_is_active: item.doctor_is_active,
        }
        )
          .then((response) => {
            this.isLoaderActive = false;
            if (response.data.success == "true") {
              Global.showSuccessAlert(true, "success", response.data.message);
              this.getDoctorDetailsList();
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;

            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        if (item.doctor_is_active == false) {
          item.doctor_is_active = true;
        } else {
          item.doctor_is_active = false;
        }
      }
    },
    //#endregion

    //#region Delete Item
    async deleteItem(item) {
      const result = await Global.showConfirmationAlert(
        `Delete ${this.entity} ${item.doctor_full_name}`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
        this.isLoaderActive = true;
        ApiService.post(ApiEndPoint.Doctor.deleteDoctorDetails, {
          doctor_id: item.doctor_id,
        })
          .then((response) => {
            this.isLoaderActive = false;
            if (response.data.success == "true") {
              Global.showSuccessAlert(true, "success", response.data.message);
              this.getDoctorDetailsList();
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;

            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    //#endregion
  },
  //#endregion
};
