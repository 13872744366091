import { render, staticRenderFns } from "./HealthArticleBlogLanguage.vue?vue&type=template&id=19e8f3e8&scoped=true&"
import script from "./HealthArticleBlogLanguage.vue?vue&type=script&lang=js&"
export * from "./HealthArticleBlogLanguage.vue?vue&type=script&lang=js&"
import style0 from "./HealthArticleBlogLanguage.vue?vue&type=style&index=0&id=19e8f3e8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19e8f3e8",
  null
  
)

export default component.exports