import { Global } from "@/helpers/global";
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: {
        name: "Login",
      },
    },
    {
      path: "/login",
      name: "Login",
      component: require("../components/login/Login.vue").default,
    },

    {
      path: "/home",
      component: require("../components/home/Home.vue").default,
      name: "Home",
      meta: {
        requiresAuth: true,
      },
      children: [
        //#region Dashboard
        {
          path: "dashboard",
          component: require("../components/dashboard/Dashboard.vue").default,
          name: "Dashboard",
          meta: {
            requiresAuth: true,
          },
        },
        //#region Master
        {
          path: "master/city/city-master",
          component: require("../components/master/city/CityMaster.vue")
            .default,
          name: "City",
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "master/area/area-master",
          component: require("../components/master/area/AreaMaster.vue")
            .default,
          name: "Area",
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "master/doctor/doctor-master",
          component: require("../components/master/doctor/DoctorMaster.vue")
            .default,
          name: "Doctor",
          meta: {
            requiresAuth: true,
          },
          props:true
        },
        {
          path: "master/clinic/clinic-master",
          component: require("../components/master/clinic/ClinicMaster.vue")
            .default,
          name: "Clinic",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "master/nursing/nursing-master",
          component: require("../components/master/nursing/NursingMaster.vue")
            .default,
          name: "Nursing",
          meta: {
            requiresAuth: true,
          },
        },

        //#endregion

         //#admitted patient
        {
          path: "admitted/registerPatient/register-patient",
          component: require("../components/admitted/registerPatient/registerPatient.vue")
            .default,
          name: "registerPatient",
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "admitted/chatGroup/chat-group",
          component: require("../components/admitted/chatGroup/chatGroup.vue")
            .default,
          name: "chatGroup",
          meta: {
            requiresAuth: true,
          },
        },
         {
          path: "admitted/chatGroup/viewGroupDetails/view-group-member",
          component:
            require("../components/admitted/chatGroup/viewGroupDetails/viewGroupDetails.vue")
              .default,
          name: "viewGroupDetails",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "admitted/ipModule/ipmodule",
          component: require("../components/admitted/ipModule/ipModule.vue")
            .default,
          name: "ipModule",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "admitted/ipModule/viewipModuleDetails/view-ip-group-member",
          component:
            require("../components/admitted/ipModule/viewipModuleDetails/viewipModuleDetails.vue")
              .default,
          name: "viewipModuleDetails",
          meta: {
            requiresAuth: true,
          },
        },

        //#region transaction
        
        //#region transaction
        {
          path: "transaction/slotDates/slot-dates",
          component:
            require("../components/transaction/slotDates/SlotDates.vue")
              .default,
          name: "OPD Booking Dates",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "transaction/videoBookingDetails/video-booking-details",
          component:
            require("../components/transaction/videoBookingDetails/VideoBookingDetails.vue")
              .default,
          name: "Video Booking Details",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "transaction/videoSlotDate/video-slot-date",
          component:
            require("../components/transaction/videoSlotDate/VideoSlotDate.vue")
              .default,
          name: "Video Booking Details",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "transaction/upcomingVideoBookingDetails/upcoming-video-booking-details",
          component:
            require("../components/transaction/upcomingVideoBookingDetails/UpcomingVideoBookingDetails.vue")
              .default,
          name: "Upcoming Video Booking Details",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "transaction/bookMedicine/book-medicine",
          component:
            require("../components/transaction/bookMedicine/BookMedicine.vue")
              .default,
          name: "Book Medicine",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "transaction/bookMedicine/viewMedicineDoc/view-medicine-doc",
          component:
            require("../components/transaction/bookMedicine/viewMedicineDoc/ViewMedicineDoc.vue")
              .default,
          name: "viewMedicineDoc",
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "transaction/bookTest/book-test",
          component:
            require("../components/transaction/bookTest/BookTest.vue")
              .default,
          name: "Book Test",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "transaction/bookTest/viewTestDoc/view-test-doc",
          component:
            require("../components/transaction/bookTest/viewTestDoc/ViewTestDoc.vue")
              .default,
          name: "viewTestDoc",
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "transaction/noOPD/no-opd",
          component: require("../components/transaction/noOPD/noOPD.vue")
            .default,
          name: "noOPD",
          meta: {
            requiresAuth: true,
          },
        },
        
        //#endregion


         //#region reports
         {
          path: "reports/bookingDetails/booking-details",
          component:
            require("../components/reports/bookingDetails/BookingDetails.vue")
              .default,
          name: "Area",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "reports/clinicBookingDetails/clinic-booking-details",
          component:
            require("../components/reports/clinicBookingDetails/ClinicBookingDetails.vue")
              .default,
          name: "Area",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "reports/upcomingClinicBookingDetails/upcoming-clinic-booking-details",
          component:
            require("../components/reports/upcomingClinicBookingDetails/UpcomingClinicBookingDetails.vue")
              .default,
          name: "Area",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "reports/regPatient/reg-patient",
          component:
            require("../components/reports/regPatient/RegPatient.vue")
              .default,
          name: "regPatient",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "reports/regPatient/viewClinicBooking/view-clinic-booking",
          component:
            require("../components/reports/regPatient/viewClinicBooking/ViewClinicBooking.vue")
              .default,
          name: "viewClinicBooking",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "reports/regPatient/viewVideoBooking/view-video-booking",
          component:
            require("../components/reports/regPatient/viewVideoBooking/ViewVideoBooking.vue")
              .default,
          name: "viewVideoBooking",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "reports/videoVisitClinicWise/video-visit-clinic-wise",
          component:
            require("../components/reports/videoVisitClinicWise/VideoVisitClinicWise.vue")
              .default,
          name: "Video Visit Clinic Wise",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "reports/doctorWisePatientDetails/doctor-wise-patient-details",
          component:
            require("../components/reports/doctorWisePatientDetails/DoctorWisePatientDetails.vue")
              .default,
          name: "Doctor Wise Patient Details",
          meta: {
            requiresAuth: true,
          },
        },

        //Health book code
         //#region Master
        {
          path: "healthbook/exerciseVideo/ExerciseHealthbook",
          component: require("../components/healthbook/exerciseVideo/ExerciseHealthbook.vue")
            .default,
          name: "Healthbook",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "healthbook/exerciseVideo/view-video",
          component:
            require("../components/healthbook/exerciseVideo/ViewVideo.vue")
              .default,
          name: "HealthbookVideo",
          meta: {
            requiresAuth: true,
          },
        },
         {
          path: "healthbook/healthtipsvideo/HealthTipbook",
          component:
            require("../components/healthbook/healthtipsvideo/HealthTipbook.vue")
              .default,
          name: "Healthtips",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "healthbook/patienttipsvideo/PatientTipVideo",
          component:
            require("../components/healthbook/patienttipsvideo/PatientTipVideo.vue")
              .default,
          name: "PatientTipVideo",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "healthbook/healthassasement/HealthAssasement",
          component:
            require("../components/healthbook/healthassasement/HealthAssasement.vue")
              .default,
          name: "HealthAssasement",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "healthbook/whatwedo/HealthWhatWeDoLanguage",
          component:
            require("../components/healthbook/whatwedo/HealthWhatWeDoLanguage.vue")
              .default,
          name: "HealthWhatWeDoLanguage",
          meta: {
            requiresAuth: true,
          },
        },
        
         {
          path: "healthbook/healthassasement/HealthBrainAssasement",
          component:
            require("../components/healthbook/healthassasement/HealthBrainAssasement.vue")
              .default,
          name: "HealthBrainAssasements",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "healthbook/faqhealth/HealthFaqLanguage",
          component:
            require("../components/healthbook/faqhealth/HealthFaqLanguage.vue")
              .default,
          name: "HealthFaqLangiage",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "healthbook/articleblogcontent/HealthArticleBlogLanguage",
          component:
            require("../components/healthbook/articleblogcontent/HealthArticleBlogLanguage.vue")
              .default,
          name: "HealthArticleBlogLanguage",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "healthbook/healthassasement/HealthLanguage",
          component:
            require("../components/healthbook/healthassasement/HealthLanguage.vue")
              .default,
          name: "HealthLanguage",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "healthbook/healthtipsvideo/HealthTipsLanguage",
          component:
            require("../components/healthbook/healthtipsvideo/HealthTipsLanguage.vue")
              .default,
          name: "HealthTipsLanguage",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "healthbook/patienttipsvideo/PatientTipsLanguage",
          component:
            require("../components/healthbook/patienttipsvideo/PatientTipsLanguage.vue")
              .default,
          name: "PatientTipsLanguage",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "healthbook/exerciseVideo/HealthExerciseLanguage",
          component:
            require("../components/healthbook/exerciseVideo/HealthExerciseLanguage.vue")
              .default,
          name: "HealthExerciseLanguage",
          meta: {
            requiresAuth: true,
          },
        },
          {
          path: "healthbook/articleblogcontent/ArticleBlog",
          component:
            require("../components/healthbook/articleblogcontent/ArticleBlog.vue")
              .default,
          name: "ArticleBlog",
          meta: {
            requiresAuth: true,
          },
        },
          {
          path: "healthbook/faqhealth/FaqHealth",
          component:
            require("../components/healthbook/faqhealth/FaqHealth.vue")
              .default,
          name: "FaqHealth",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "healthbook/faqhealth/view-video",
          component:
            require("../components/healthbook/faqhealth/FaqVideo.vue")
              .default,
          name: "FaqVideo",
          meta: {
            requiresAuth: true,
          },
        },
          {
          path: "healthbook/whatwedo/WhatWeDo",
          component:
            require("../components/healthbook/whatwedo/WhatWeDo.vue")
              .default,
          name: "WhatWeDo",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "slidersetting/homepageslider/HomePageSlider",
          component:
            require("../components/slidersetting/homepageslider/HomePageSlider.vue")
              .default,
          name: "HomePageSlider",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "slidersetting/healthbookslider/HealthBookSlider",
          component:
            require("../components/slidersetting/healthbookslider/HealthBookSlider.vue")
              .default,
          name: "HealthBookSlider",
          meta: {
            requiresAuth: true,
          },
        },
          {
          path: "healthbook/whatwedo/WhatWeDoVideo",
          component:
            require("../components/healthbook/whatwedo/WhatWeDoVideo.vue")
              .default,
          name: "HealthWhatWeDo",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "notificationsetting/notification/NotificationUser",
          component:
            require("../components/notificationsetting/notification/NotificationUser.vue")
              .default,
          name: "NotificationUser",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "paymentDetails/payment/PaymentDetails",
          component:
            require("../components/paymentDetails/payment/PaymentDetails.vue")
              .default,
          name: "PaymentDetails",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "icusetting/icu/Icu",
          component:
            require("../components/icusetting/icu/Icu.vue")
              .default,
          name: "Icu",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "juniordoctorsetting/juniordoctor/juniorDoctor",
          component:
            require("../components/juniordoctorsetting/juniordoctor/juniorDoctor.vue")
              .default,
          name: "juniorDoctor",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "clinicstaffSetting/clinicstaff/ClinicStaff",
          component:
            require("../components/clinicstaffSetting/clinicstaff/ClinicStaff.vue")
              .default,
          name: "ClinicStaff",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "pharmacysetting/pharmacy/Pharmacy",
          component:
            require("../components/pharmacysetting/pharmacy/Pharmacy.vue")
              .default,
          name: "Pharmacy",
          meta: {
            requiresAuth: true,
          },
        },
        //#endregion
      ],
    },
    {
      path: "*",
      component: require("../components/NotFound.vue").default,
    },
  ],
});

router.beforeEach((to, from, next) => {
  console.log(secureLS.get(Global.tokenKey));
  try {
    //* Check if the route requires authentication & user not logged in
    if (
      to.matched.some((route) => route.meta.requiresAuth) &&
      secureLS.get(Global.tokenKey) == ""
    ) {
      next({
        name: "Login",
      });
      return;
    }

    //* if logged in redirect to dashboard
    if (to.path === "/login" && secureLS.get(Global.tokenKey) != "") {
      next({
        name: "Dashboard",
      });
      return;
    }
    next();
  } catch (err) {
    secureLS.removeAll();
    router.push({ name: "Login" });
  }
});
export default router;
