import { render, staticRenderFns } from "./viewGroupDetails.vue?vue&type=template&id=0e13db74&scoped=true&"
import script from "./viewGroupDetails.vue?vue&type=script&lang=js&"
export * from "./viewGroupDetails.vue?vue&type=script&lang=js&"
import style0 from "./viewGroupDetails.vue?vue&type=style&index=0&id=0e13db74&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e13db74",
  null
  
)

export default component.exports