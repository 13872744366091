import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";

export const viewvideo = {
  props: ["userPermissionDataProps"],
  mixins: [globalMixin],
  data() {
    return {
      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Video Link",
          value: "video_link",
          width: "50%",
          sortable: true,
          align: "start",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "25%",
          align: "end",
        },
       
      ],

      pagination: {},
      entity: "Exercise Video",
      // search
      searchText: "",
      // add edit
      defaultItem: {},
      payLoad: {},
      totalItemsInDB: 0,
      item: {},
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Update Exercise",
      addEditText: "Add",
      isLoaderActive: false,
      isDialogLoaderActive: false,

      tableItems: [],
      tableDataLoading: false,

      //end

      //excel
      excelFields: {
        ID: "product_mode_id",
        Name: "product_mode",
      },
      excelFileName:
        //  "CityMaster" + "_" + moment().format("DD/MM/YYYY") + ".xls",
        "CityMaster" + "_aaaa.xls",
      //end
    };
  },
  created() {
    this.exersice_ids = this.$route.query.exersice_id;
    this.getDetails();

  },
  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  watch: {
    addEditDialog(value) {
      return value ? true : this.close();
    },
    pagination: {
      handler() {
        this.getDetails();
      },
      deep: true,
    },
  },
  methods: {
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getDetails();
      }, 500);
    },
    close() {
      this.addEditDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, this.defaultItem);
      }, 300);
      window.location.reload();
    },
    // add edit
    // New Code Add By Ajay Add Video 05/07/2023 Start
    addEditItem() {

      if (this.$refs.holdingFormAddEdit.validate()) {
        if (this.isAddEdit) {
          // save
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          postData.append("video_link", this.item.video_link);
          postData.append("exersice_ids", this.exersice_ids);
         
          
          
          ApiService.post(ApiEndPoint.ExersiceVideo.savExersiceVideo, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {

          //update
          this.isDialogLoaderActive = true;
          let postData = new FormData();
           
          postData.append("video_link", this.item.video_link);
          postData.append("exersice_ids", this.exersice_ids);
          postData.append("Id", this.item.video_id);
          ApiService.post(ApiEndPoint.ExersiceVideo.updatExersiceVideo, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },
    // End
    //Hide Code Ajay 05/07/2023
    // addEditItem() {
    //   if (this.$refs.holdingFormAddEdit.validate()) {
    //     if (this.isAddEdit) {
    //       // save
    //       this.apiCallPost(ApiEndPoint.ExersiceVideo.savExersiceVideo, {
    //         video_link: this.item.video_link,
    //        exersice_ids: this.exersice_ids,
    //       });
    //       this.close();
    //     } else {
    //       this.apiCallPost(ApiEndPoint.ExersiceVideo.updatExersiceVideo, {
    //         video_link: this.item.video_link,
    //         exersice_ids: this.exersice_ids,
    //         Id: this.item.video_id,
    //       });

    //       this.close();
    //     }
    //   }
    // },
    //End
    //show add edit dialog
    showAddEditDialog(item) {
      if (item == null && this.isAddEdit == true) {
        this.addEditText = `Add New ${this.entity}`;
        this.addEditDialog = true;
        this.addUpdateButtonText = " Add ";
      } else {
        this.item = Object.assign({}, item);
        this.addEditText = `Edit ${this.entity} : `;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
      }
    },
    // enable disable
    async enableDisableItem(item) {
      console.log(item);
      const result = await Global.showConfirmationAlert(
        `Change  ${this.entity} : ${item.exersice_title} Status`,
        "Are you sure to change the status",
        "warning"
      );
      if (result.isConfirmed) {
        this.apiCallPost(ApiEndPoint.Exersice.changExersiceStatus, {
          Id: item.exersice_id,
          exersice_status: item.exersice_is_active,
        });
      } else {
        if (item.exersice_is_active == false) {
          item.exersice_is_active = true;
        } else {
          item.exersice_is_active = false;
        }
      }
    },
    // #region Get Details
    getDetails() {
      console.log("Entry");

      this.isLoaderActive = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "desc" : "asc";
      sortBy = sortBy.length == 0 ? `exersice_id` : sortBy[0];
      ApiService.get(ApiEndPoint.ExersiceVideo.getExersiceVideo, {
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
         exersice_ids: this.exersice_ids,
      })
        .then((response) => {
          this.isLoaderActive = false;
          console.log("City", response.data.resultData.data);

          this.tableItems = response.data.resultData.data;
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          this.isLoaderActive = false;
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    // delete
    async deleteItem(item) {
      const result = await Global.showConfirmationAlert(
        `Delete Specialization ${item.exersice_title}`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
        this.apiCallPost(ApiEndPoint.ExersiceVideo.deletExersiceVideo, {
          video_id: item.video_id,
        });
      }
    },

    apiCallPost(endPoint, parameter) {
      ApiService.post(endPoint, parameter)
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.success == "true") {
            Global.showSuccessAlert(true, "success", response.data.message);
            this.getDetails();
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          console.log(error);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    // #endregion
  },
};
