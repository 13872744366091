import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
export const registerPatient = {
  props: ["userPermissionDataProps"],
  mixins: [globalMixin],
  data() {
    return {
      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Full Name",
          value: "patient_full_name",
          width: "25%",
          sortable: true,
          align: "start",
        },

        {
          text: "Mobile Number",
          value: "family_member_mobile_number",
          sortable: false,
          width: "25%",
          align: "start",
        },
         {
          text: "UHID",
          value: "uhid",
          sortable: false,
          width: "25%",
          align: "start",
        },
       {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "25%",
          align: "end",
        },
        
      ],
      tableItems: [],
      PatientList: [],
      TreatmentTypeOptions: ['Surgical','Nonsurgical'],
      HighRiskOptions: ['Yes','No'],
      StatusOptions: ['Admit','Discharge'],
      totalItemsInDB: 0 ,
      tableDataLoading: false,
      item: {},
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Admit Patient",
      addEditText: "Add",
      isDialogLoaderActive: false,
      show: true,
      isLoaderActive: false,
      pagination: {},
      module: "Admitted Patients",
      entity: "Patient",
      
      //Dialog box clinic slot dates
      patient_admin_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
      )
          .toISOString()
          .substr(0, 10),
      menu_patient_admin_date: false,

      // patient_discharge_date: new Date(
      //     Date.now() - new Date().getTimezoneOffset() * 60000
      // )
      //     .toISOString()
      //     .substr(0, 10),
      // menu_patient_discharge_date: false,

      // search
      searchText: "",


     
    };
  },
  //#region created
  created() {
    // To get Details in List
    this.getDetails();
  },
  //#endregion

   //#region loading Patients on page load/ mount 
  mounted() {
    // Show Add in dialog
    this.getPatient();
   
  },
  //#endregion

  //#region computed
  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  //#endregion

  //#region watch
  watch: {
    addEditDialog(value) {
      return value ? true : this.close();
    },
    pagination: {
      handler() {
        this.getDetails();
      },
      deep: true,
    },
  },
  //#endregion
  //#region 
  methods: {
    //#region To get the Details list
    getDetails() {
      this.isLoaderActive = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "asc" : "desc";
      sortBy = sortBy.length == 0 ? `user_id` : sortBy[0];
      ApiService.get(ApiEndPoint.RegisterPatient.getRegisteredPatient, {
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
      })
        .then((response) => {
          this.isLoaderActive = false;
          this.tableItems = response.data.resultData.data;
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          this.isLoaderActive = false;
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#region search Info
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getDetails();
      }, 500);
    },
    //#endregion
     close() {
      this.addEditDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, this.defaultItem);
      }, 300);
    },
    addEditItem() {
     
      if (this.$refs.holdingFormAddEdit.validate()) {
        if (this.isAddEdit) {
            // save
            console.log(this.item);
            this.isDialogLoaderActive = true;
            ApiService.post(ApiEndPoint.RegisterPatient.admitPatientByAdmin, {
              uhid: this.item.uhid,
              patient_id: this.item.patient_id,
              treatment_type: this.item.treatment_type,
              bed_number: this.item.bed_number,
              case_history: this.item.case_history,
              high_risk: this.item.high_risk,
              patient_admin_date: this.item.patient_admin_date,
              //patient_discharge_date: this.item.patient_discharge_date,
              status: this.item.status,
             // days_after_discharge_chat_window: this.item.days_after_discharge_chat_window,
            })
              .then((response) => {
                this.isDialogLoaderActive = false;
                this.close();
                if (response.data.result == "success") {
                  Global.showSuccessAlert(true, "success", response.data.message);
                 this.getDetails();
                } else if (response.data.result == "error") {
                  Global.showErrorAlert(true, "error", response.data.message);
                }
              })
              .catch((error) => {
                this.isDialogLoaderActive = false;

                if (
                  error.response.status != 401 ||
                  error.response.status != 403
                ) {
                  Global.showErrorAlert(true, "error", "Something went wrong");
                }
              });
          } 
        else {
            //update

            this.isDialogLoaderActive = true;
            ApiService.post(ApiEndPoint.RegisterPatient.UpdateAdmitPatientDetails, {
               uhid: this.item.uhid,
              patient_id: this.item.patient_id,
              treatment_type: this.item.treatment_type,
              bed_number: this.item.bed_number,
              case_history: this.item.case_history,
              high_risk: this.item.high_risk,
              patient_admin_date: this.item.patient_admin_date,
              //patient_discharge_date: this.item.patient_discharge_date,
              status: this.item.status,
              //days_after_discharge_chat_window: this.item.days_after_discharge_chat_window,
            })
              .then((response) => {
                this.isDialogLoaderActive = false;
                this.close();
                if (response.data.result == "success") {
                  Global.showSuccessAlert(true, "success", response.data.message);
                  this.getDetails();
                } else if (response.data.result == "error") {
                  Global.showErrorAlert(true, "error", response.data.message);
                }
              })
              .catch((error) => {
                this.isDialogLoaderActive = false;

                if (
                  error.response.status != 401 ||
                  error.response.status != 403
                ) {
                  Global.showErrorAlert(true, "error", "Something went wrong");
                }
              });
          }
      }
    },

    //show add edit dialog
   

    showAddEditDialog(item) {
     
       if (item == null && this.isAddEdit == true) {
        this.addEditText = `Admit Patient`;
        this.addEditDialog = true;
        this.addUpdateButtonText = " Add ";
      } else {
        this.item = Object.assign({}, item);
        this.addEditText = `Edit ${this.entity} : ` + item.patient_full_name;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
        this.getPatient();
      }

      // this.item = Object.assign({}, item);
      // this.addEditText = `${this.entity} : `+ item.family_member_full_name;
      // this.addEditDialog = true;
      // this.addUpdateButtonText = "Update";
      
    },

    //#region  to load Patient
    getPatient() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.RegisterPatient.getPatientListWithoutUhid,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.PatientList = response.data.resultData;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    apiCallPost(endPoint, parameter) {
      ApiService.post(endPoint, parameter)
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.success == "true") {
            Global.showSuccessAlert(true, "success", response.data.message);
            this.getDetails();
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          console.log(error);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },

  },
  //#endregion
};
