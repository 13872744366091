import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
export const chatGroup = {
  props: ["userPermissionDataProps","addGroupDataProps"],
  mixins: [globalMixin],
  data() {
    return {
      DoctorId:
        this.addGroupDataProps != null 
          ? this.addGroupDataProps.item.doctor_id.split(",").map(Number)
          : null,
      PatientId:
        this.addGroupDataProps != null 
          ? this.addGroupDataProps.item.family_member_id.split(",").map(Number)
          : null,
      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Group Name",
          value: "group_name",
          width: "25%",
          sortable: true,
          align: "start",
        },
        {
          text: "Patient Mobile",
          value: "patient_mobile_number",
          width: "25%",
          sortable: true,
          align: "start",
        },
        
       {
          text: "Add More Doctor",
          value: "actions_add_doctor",
          sortable: false,
          width: "15%",
          align: "start",
        },  
        {
          text: "Add Family Member",
          value: "actions_add_member",
          sortable: false,
          width: "15%",
          align: "start",
        },
        {
          text: "View Group Members",
          value: "actions_view_member",
          sortable: false,
          width: "30%",
          align: "center",
        },
        {
          text: "Status",
          value: "group_is_active",
          sortable: false,
          width: "15%",
          align: "start",
        },
      ],
      tableItems: [],
      totalItemsInDB: 0 ,
      tableDataLoading: false,
     
      show: true,
      isLoaderActive: false,
      pagination: {},
      module: "Admitted Patients ",
      entity: "Chat Group",
      
       //Doctor
      addDoctorDialog: false,
      isFormAddDoctorValid: false,
      isAddDoctor: true,
      addUpdateButtonText: "Add More Doctor",
      addDoctorText: "Add Doctor In Group",

       //Members
      addMemberDialog: false,
      isFormAddMemberValid: false,
      isAddMember: true,
      addUpdateButtonText: "Add Family Member",
      addMemberText: "Add Family Member",

      // search
      searchText: "",

      item: {},
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Add Group",
      addEditText: "Add",
      isDialogLoaderActive: false,

      tableItems: [],
      doctorItems: [],
      patientItems: [],
     
    };
  },
  //#region created
  created() {
    // To get Details in List
    this.getDetails();
  },
  //#endregion

  //#region computed
  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  //#endregion

  //#region watch
  watch: {
    pagination: {
      handler() {
        this.getDetails();
      },
      deep: true,
    },
  },
  //#endregion

   //#region loading Doctor, Patient
  mounted() {
    // Show Add in dialog

    this.getDoctor();
    this.getPatient();
     this.getFamilyMember();
        this.getRelation();   
  },
  //#endregion

  //#region 
  methods: {
    //#region To get the Details list
    getDetails() {
      this.isLoaderActive = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "asc" : "desc";
      sortBy = sortBy.length == 0 ? `user_id` : sortBy[0];
      ApiService.get(ApiEndPoint.chatGroup.getChatGroup, {
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
      })
        .then((response) => {
          this.isLoaderActive = false;
          this.tableItems = response.data.resultData.data;
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          this.isLoaderActive = false;
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#region search Info
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getDetails();
      }, 500);
    },
    //#endregion
     close() {
      this.addEditDialog = false;
      this.addDoctorDialog = false;
      this.addMemberDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, this.defaultItem);
      }, 300);
    },
     //#region  enable disable
    async enableDisableItem(item) {
      const result = await Global.showConfirmationAlert(
        `Change  ${this.entity} : ${item.group_name} Status`,
        "Are you sure to change the status",
        "warning"
      );
      if (result.isConfirmed) {
        this.isLoaderActive = true;

        ApiService.post(
          ApiEndPoint.chatGroup.changeGroupStatus, {
          group_id: item.group_id,
          group_is_active: item.group_is_active,
        }
        )
          .then((response) => {
            this.isLoaderActive = false;
            if (response.data.success == "true") {
              Global.showSuccessAlert(true, "success", response.data.message);
              this.getDoctorDetailsList();
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;

            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        if (item.group_is_active == false) {
          item.group_is_active = true;
        } else {
          item.group_is_active = false;
        }
      }
    },
    //#endregion
    addEditItem() {
      if (this.$refs.holdingFormAddEdit.validate()) {
        
          let postData = new FormData();
          console.log(this.PatientId);
          postData.append("DoctorId", this.DoctorId!=null? this.DoctorId.toString():null);
          postData.append("PatientId", this.PatientId!=null? this.PatientId.toString():null);
          
          postData.append("max_member", this.item.max_member);
         
          ApiService.post(ApiEndPoint.chatGroup.addChatGroup, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });

          this.close();
       
      }

    },

    //show add edit dialog
    showAddEditDialog(item) {
     
      this.item = Object.assign({}, item);
      this.addEditText = `Add ${this.entity} `;
      this.addEditDialog = true;
      this.addUpdateButtonText = "Add";
      
    },

    //#region  show add Members dialog
    showAddDoctorDialog(item) {
       // this.item = null;     
        this.item = Object.assign({}, item);
        //this.addMemberText = `${this.addMemberText} : ` + item.group_name;
        this.addDoctorText = `${this.addDoctorText} `;
        this.addDoctorDialog = true;
        this.addUpdateButtonText = "Add";
        
      
    },
    //#endregion

    //#region  show add Members dialog
    showAddMemberDialog(item) {
       // this.item = null;     
        this.item = Object.assign({}, item);
        //this.addMemberText = `${this.addMemberText} : ` + item.group_name;
        this.addMemberText = `${this.addMemberText} `;
        this.addMemberDialog = true;
        this.addUpdateButtonText = "Add";
        this.getFamilyMember();
        this.getRelation();
    },
    //#endregion

     //#region  add More Doctor
    addMoreDoctor() {
      if (this.$refs.holdingFormAddDoctor.validate()) {
        console.log(this.item);
        if (this.isAddDoctor) {
          // save
          console.log(this);
           console.log(this.DoctorId);
          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.chatGroup.addDoctorInGroup, {
            group_id: this.item.group_id,
            doctor_id: this.DoctorId,
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } 
      }
      this.close();
    },
    //#endregion

    //#region  add Family Member
    addFamilyMember() {
      if (this.$refs.holdingFormAddMember.validate()) {
        //console.log(this.item);
        if (this.isAddMember) {
          // save
          //console.log(this.RelationId);
           //console.log(this.FamilyMemberId);
          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.chatGroup.addFamilyMemberInGroup, {
            group_id: this.item.group_id,
            family_member_id: this.FamilyMemberId,
            relation_id: this.RelationId,
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } 
      }
      this.close();
    },
    //#endregion

     //#region  to load Doctor
    getDoctor() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.chatGroup.getDoctorIdWithoutPagination,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.doctorItems = response.data.resultData;
          console.log(this.item.group_id);
           this.DoctorId=this.item.doctor_id.split(",").map(Number);
          
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  to load Patient
    getPatient() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.chatGroup.getPatientIdWithoutPagination,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.patientItems = response.data.resultData;
          console.log(this.item.family_member_id);
          this.PatientId=this.item.family_member_id != null  ? this.item.family_member_id.split(",").map(Number) : null;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  to load Family Member
    getFamilyMember() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.chatGroup.getAllFamilyMemberList,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.FamilyMemberList = response.data.resultData;
          console.log(this.item);
          this.FamilyMemberId=this.item.family_member_id != null  ? this.item.family_member_id.split(",").map(Number) : null;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  to load Relation List
    getRelation() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.chatGroup.getAllRelationList,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.RelationList = response.data.resultData;
          console.log(this.item);
          this.RelationId=this.item.relation_id != null  ? this.item.relation_id.split(",").map(Number) : null;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    apiCallPost(endPoint, parameter) {
      ApiService.post(endPoint, parameter)
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.success == "true") {
            Global.showSuccessAlert(true, "success", response.data.message);
            this.getDetails();
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          console.log(error);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },

     viewGroupDetails(item){
      this.$router.push({
        name: "viewGroupDetails",
        query: {
          group_id: item.group_id,
        },
      });
    },

  },
  //#endregion
};
