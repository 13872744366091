import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
export const ipModule = {
  props: ["userPermissionDataProps","addGroupDataProps","addchataccessDataProps"],
  mixins: [globalMixin],
  data() {
    return {
      IcuId:
        this.addGroupDataProps != null 
          ? this.addGroupDataProps.item.icu_id.split(",").map(Number)
          : null,
      SponsorId:
        this.addGroupDataProps != null 
          ? this.addGroupDataProps.item.sponsor_id.split(",").map(Number)
          : null,
      PatientId:
        this.addGroupDataProps != null 
          ? this.addGroupDataProps.item.family_member_id.split(",").map(Number)
          : null,
      ChatId:
        this.addchataccessDataProps != null 
          ? this.addchataccessDataProps.item.chat_type.split(",")
          : null,
      DoctorId:
        this.addGroupDataProps != null 
          ? this.addGroupDataProps.item.doctor_id.split(",").map(Number)
          : null,
      JrDoctorId:
        this.addGroupDataProps != null 
          ? this.addGroupDataProps.item.doctor_id.split(",").map(Number)
          : null,
      StaffId:
        this.addGroupDataProps != null 
          ? this.addGroupDataProps.item.doctor_id.split(",").map(Number)
          : null,
      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Ip Name",
          value: "family_member_full_name",
          width: "25%",
          sortable: true,
          align: "start",
        },
        {
          text: "Uhid",
          value: "uhid",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
          text: "Admission Date",
          value: "admission_date",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
          text: "Discharge Date",
          value: "discharge_date",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
          text: "Sponsor Category",
          value: "sponsor_name",
          width: "15%",
          sortable: true,
          align: "start",
        },   
       {
          text: "Add More Doctor",
          value: "actions_add_doctor",
          sortable: false,
          width: "10%",
          align: "start",
        }, 
        {
          text: "Add More Jr Doctor",
          value: "actions_add_jrdoctor",
          sortable: false,
          width: "10%",
          align: "start",
        }, 
        {
          text: "Add More Staff",
          value: "actions_add_staff",
          sortable: false,
          width: "10%",
          align: "start",
        },
        {
          text: "Add Family Member",
          value: "actions_add_member",
          sortable: false,
          width: "10%",
          align: "start",
        },
        {
          text: "View Ip Members",
          value: "actions_view_member",
          sortable: false,
          width: "30%",
          align: "center",
        },
        {
          text: "Status",
          value: "is_active",
          sortable: false,
          width: "15%",
          align: "start",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "40%",
          align: "end",
        },
      ],
      tableItems: [],
      totalItemsInDB: 0 ,
      tableDataLoading: false,
     
      show: true,
      isLoaderActive: false,
      pagination: {},
      module: "Admitted Patients",
      entity: "Ip Module",
      
       //Doctor
      addDoctorDialog: false,
      isFormAddDoctorValid: false,
      isAddDoctor: true,
      addUpdateButtonText: "Add More Doctor",
      addDoctorText: "Add Doctor In Group",

      // Jr Doctor
      addJrDoctorDialog: false,
      isFormAddJrDoctorValid: false,
      isAddJrDoctor: true,
      addUpdateButtonText: "Add More Jr.Doctor",
      addJrDoctorText: "Add Jr.Doctor In Group",

      // Staff
      addStaffDialog: false,
      isFormAddStaffValid: false,
      isAddStaff: true,
      addUpdateButtonText: "Add More Staff",
      addStaffText: "Add Staff In Group",

      //Chat Type
      chatItems: ['Internal','External'],
      chatItem:  ['External'],
      ChatType: "External",
      //Members
      addMemberDialog: false,
      isFormAddMemberValid: false,
      isAddMember: true,
      addUpdateButtonText: "Add Family Member",
      addMemberText: "Add Family Member",

      // search
      searchText: "",

      item: {},
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Add Group",
      addEditText: "Add",
      isDialogLoaderActive: false,

      tableItems: [],
      IcuItems: [],
      sponsorItems: [],
      patientItems: [],
      doctorItems: [],
      jrdoctorItems: [],
      StaffItems: [],

      //home Page clinic booking from date and to date
      in_ip_from_date: new Date(
        new Date(new Date().setDate(1)).toISOString()
      )
        .toISOString()
        .substr(0, 10),
      menu_in_ip_from_date: false,

      in_ip_to_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      menu_in_ip_to_date: false,
     
    };
  },
  //#region created
  created() {
    // To get Details in List
    this.getDetails();
  },
  //#endregion

  //#region computed
  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  //#endregion

  //#region watch
  watch: {
    pagination: {
      handler() {
        this.getDetails();
      },
      deep: true,
    },
  },
  //#endregion

   //#region loading Doctor, Patient
  mounted() {
    // Show Add in dialog

    this.getICU();
    this.getSponsor();
    this.getPatient();
    this.getFamilyMember();
    this.getRelation();
    this.getDoctor(); 
    this.getJrDoctor();  
    this.getStaff();  
  },
  //#endregion

  //#region 
  methods: {
    //#region To get the Details list
    getDetails() {
      this.isLoaderActive = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "asc" : "desc";
      sortBy = sortBy.length == 0 ? `user_id` : sortBy[0];
      ApiService.get(ApiEndPoint.ipModule.getIpModule, {
        in_ip_from_date: this.in_ip_from_date,        
        in_ip_to_date: this.in_ip_to_date,
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
      })
        .then((response) => {
          this.isLoaderActive = false;
          this.tableItems = response.data.resultData.data;
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          this.isLoaderActive = false;
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#region search Info
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getDetails();
      }, 500);
    },
    //#endregion
     close() {
      this.addEditDialog = false;
      this.addDoctorDialog = false;
      this.addJrDoctorDialog = false;
      this.addStaffDialog = false;
      this.addMemberDialog = false;
      window.location.reload();

      setTimeout(() => {
        this.item = Object.assign({}, this.defaultItem);
      }, 300);
    },
     //#region  enable disable
    async enableDisableItem(item) {
      const result = await Global.showConfirmationAlert(
        `Change  ${this.entity} : ${item.family_member_full_name} Status`,
        "Are you sure to change the status",
        "warning"
      );
      if (result.isConfirmed) {
        this.isLoaderActive = true;

        ApiService.post(
          ApiEndPoint.ipModule.changeIpModuleStatus, {
          id: item.id,
          is_active: item.is_active,
        }
        )
          .then((response) => {
            this.isLoaderActive = false;
            if (response.data.success == "true") {
              Global.showSuccessAlert(true, "success", response.data.message);
              this.getDoctorDetailsList();
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;

            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        if (item.is_active == false) {
          item.is_active = true;
        } else {
          item.is_active = false;
        }
      }
    },
    //#endregion
    addEditItem() {
      if (this.$refs.holdingFormAddEdit.validate()) {
        if (this.isAddEdit) {
          let postData = new FormData();
          console.log(this.PatientId);
          postData.append("patient_id", this.PatientId!=null? this.PatientId.toString():null);
          postData.append("bed_no", this.item.bed_no);
          postData.append("icu_id", this.IcuId!=null? this.IcuId.toString():null);
          postData.append("uhid", this.item.uhid);
          postData.append("case_history", this.item.case_history);
          postData.append("admission_date", this.item.admission_date);
          postData.append("alternate_mobile_no", this.item.alternate_mobile_no);
          postData.append("sponsor_id", this.SponsorId!=null? this.SponsorId.toString():null);
          postData.append("is_internal_chat", this.item.is_internal_chat);
          postData.append("is_external_chat", this.item.is_external_chat);          
         
          ApiService.post(ApiEndPoint.ipModule.addIpModules, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });       
      }else {
          //update
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          postData.append("patient_id", this.PatientId!=null? this.PatientId.toString():null);
          postData.append("bed_no", this.item.bed_no);
          postData.append("icu_id", this.IcuId!=null? this.IcuId.toString():null);
          postData.append("uhid", this.item.uhid);
          postData.append("case_history", this.item.case_history);
          postData.append("admission_date", this.item.admission_date);
          postData.append("alternate_mobile_no", this.item.alternate_mobile_no);
          postData.append("sponsor_id", this.SponsorId!=null? this.SponsorId.toString():null);
          postData.append("is_internal_chat", this.item.is_internal_chat);
          postData.append("is_external_chat", this.item.is_external_chat);

          postData.append("id", this.item.id);
          ApiService.post(ApiEndPoint.ipModule.updatIpModules, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },

    //show add edit dialog
    showAddEditDialog(item) {
     if (item == null && this.isAddEdit == true) {
      this.item = Object.assign({}, item);
      this.addEditText = `Add ${this.entity} `;
      this.addEditDialog = true;
      this.addUpdateButtonText = "Add";  
      this.PatientId=null; 
      this.SponsorId=null; 
      this.IcuId=null; 

         
    } else { 
      this.item = Object.assign({}, item);
      console.log(this.item);
      this.addEditText = `Edit ${this.entity}`;
      this.addEditDialog = true;
      this.addUpdateButtonText = "Update";
      this.PatientId=this.item.patient_id;
      console.log(this.PatientId);
      this.SponsorId=this.item.sponsor_id;
      this.IcuId=this.item.icu_id;
    
    }
    },

    //#region  show add Members dialog
    showAddDoctorDialog(item) {
       // this.item = null;     
        this.item = Object.assign({}, item);
        //this.addMemberText = `${this.addMemberText} : ` + item.group_name;
        this.addDoctorText = `${this.addDoctorText} `;
        this.addDoctorDialog = true;
        this.addUpdateButtonText = "Add";        
      
    },
    //#endregion

    //#region  show add Jr Doctor Members dialog
    showAddJrDoctorDialog(item) {
       // this.item = null;     
        this.item = Object.assign({}, item);
        //this.addMemberText = `${this.addMemberText} : ` + item.group_name;
        this.addJrDoctorText = `${this.addJrDoctorText} `;
        this.addJrDoctorDialog = true;
        this.addUpdateButtonText = "Add";        
      
    },

    //#region  show add Staff Members dialog
    showAddStaffDialog(item) {
       // this.item = null;     
        this.item = Object.assign({}, item);
        //this.addMemberText = `${this.addMemberText} : ` + item.group_name;
        this.addStaffText = `${this.addStaffText} `;
        this.addStaffDialog = true;
        this.addUpdateButtonText = "Add";        
      
    },
    //#endregion
    //#region  show add Members dialog
    showAddMemberDialog(item) {
       // this.item = null;     
        this.item = Object.assign({}, item);
        //this.addMemberText = `${this.addMemberText} : ` + item.group_name;
        this.addMemberText = `${this.addMemberText} `;
        this.addMemberDialog = true;
        this.addUpdateButtonText = "Add";
        this.getFamilyMember();
        this.getRelation();
    },
    //#endregion

    //#region  add More Doctor
    addMoreDoctor() {
      if (this.$refs.holdingFormAddDoctor.validate()) {
        console.log(this.item);
        if (this.isAddDoctor) {
          // save
          console.log(this);
           console.log(this.DoctorId);
          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.ipModule.addDoctorInIpModule, {
            id: this.item.id,
            doctor_id: this.DoctorId,
            chat_type: this.ChatType,
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } 
      }
      // this.close();
    },
    // #endregion

    //#region  add More JrDoctor
    addMoreJrDoctor() {
      if (this.$refs.holdingFormAddJrDoctor.validate()) {
        if (this.isAddJrDoctor) {
          // save
          console.log(this);
          console.log(this.JrDoctorId);
        console.log(this.item);
          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.ipModule.addJrDoctorInIpModule, {
            id: this.item.id,
            jrdoctor_id: this.JrDoctorId,
            chat_type: this.ChatType,
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } 
      }
     // this.close();
    },
    // #endregion

    //#region  add More Staff
    addMoreStaff() {
      if (this.$refs.holdingFormAddStaff.validate()) {
        if (this.isAddStaff) {
          // save
          console.log(this);
          console.log(this.StaffId);
          console.log(this.item);
          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.ipModule.addStaffInIpModule, {
            id: this.item.id,
            staff_id: this.StaffId,
            chat_type: this.ChatType,
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } 
      }
      // this.close();
    },
    // #endregion

    //#region  add Family Member
    addFamilyMember() {
      if (this.$refs.holdingFormAddMember.validate()) {
        //console.log(this.item);
        if (this.isAddMember) {
          // save
          //console.log(this.RelationId);
           //console.log(this.FamilyMemberId);
          this.isDialogLoaderActive = true;
          ApiService.post(ApiEndPoint.ipModule.addFamilyMemberInIpModule, {
            id: this.item.id,
            family_member_id: this.FamilyMemberId,
            relation_id: this.RelationId,
            chat_type: this.ChatType,
          })
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.result == "success") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDetails();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } 
      }
      // this.close();
    },
    //#endregion

    //#region  to load ICU
    getICU() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.ipModule.getIpIcuIdWithoutPagination,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.IcuItems = response.data.resultData;
          console.log(this.IcuItems);
           this.IcuId=this.item.icu_id.split(",").map(Number);
          
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    // #endregion

    //#region  to load Sponsor
    getSponsor() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.ipModule.getIpSponsorIdWithoutPagination,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.sponsorItems = response.data.resultData;
          this.SponsorId=this.item.sponsor_id != null  ? this.item.sponsor_id.split(",").map(Number) : null;
           // this.SponsorId=this.item.sponsor_id.split(",").map(Number);
          console.log(this.SponsorId);
          
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    // #endregion

    //#region  to load Patient
    getPatient() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.ipModule.getIpPatientIdWithoutPagination,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.patientItems = response.data.resultData;
          console.log(this.item.family_member_id);
          this.PatientId=this.item.family_member_id != null  ? this.item.family_member_id.split(",").map(Number) : null;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  to load Doctor
    getDoctor() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.ipModule.getIpDoctorIdWithoutPagination,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.doctorItems = response.data.resultData;
          console.log(this.item.group_id);
           this.DoctorId=this.item.doctor_id.split(",").map(Number);
          
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  to load Jr Doctor
    getJrDoctor() {
    this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.ipModule.getIpJrDoctorIdWithoutPagination,
       {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.jrdoctorItems = response.data.resultData;
          console.log(this.jrdoctorItems);
          this.JrDoctorId=this.item != null  ? this.item.jr_doctor_id.split(",").map(Number) : null;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  to load Staff
    getStaff() {
    this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.ipModule.getIpStaffIdWithoutPagination,
       {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.StaffItems = response.data.resultData;
          console.log(this.StaffItems);
          this.StaffId=this.item != null  ? this.item.staff_id.split(",").map(Number) : null;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  to load Family Member
    getFamilyMember() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.ipModule.getAllIpFamilyMemberList,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.FamilyMemberList = response.data.resultData;
          console.log(this.item);
          this.FamilyMemberId=this.item.family_member_id != null  ? this.item.family_member_id.split(",").map(Number) : null;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  to load Relation List
    getRelation() {
      this.isDialogLoaderActive = true;
      ApiService.get(
        ApiEndPoint.ipModule.getAllIpRelationList,
        {}
      )
        .then((response) => {
          this.isDialogLoaderActive = false;
          this.RelationList = response.data.resultData;
          console.log(this.item);
          this.RelationId=this.item.relation_id != null  ? this.item.relation_id.split(",").map(Number) : null;
        })
        .catch((error) => {
          this.isDialogLoaderActive = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    apiCallPost(endPoint, parameter) {
      ApiService.post(endPoint, parameter)
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.success == "true") {
            Global.showSuccessAlert(true, "success", response.data.message);
            this.getDetails();
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          console.log(error);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },

     viewipModuleDetails(item){
      this.$router.push({
        name: "viewipModuleDetails",
        query: {
          id: item.id,
        },
      });
    },

  },
  //#endregion
  // add line 192 pachi
  // <v-row class="mx-auto d-flex">
  //                 <v-col cols="12" md="9" class="pt-5">
  //                   <v-autocomplete
  //                     v-model="DoctorId"
  //                     :items="doctorItems"
  //                     item-text="doctor_full_name"
  //                     item-value="doctor_id"
  //                     outlined
  //                     dense
  //                     chips
  //                     small-chips
  //                     multiple
                      
  //                     :rules="validationRulesRequired"
  //                     ><template #label>
  //                       Select Doctor

  //                       <span class="red--text">
  //                         <strong>*</strong>
  //                       </span>
  //                     </template>
  //                   </v-autocomplete>
  //                 </v-col>
  //               </v-row>

  // add line 218

  // <v-row class="mx-auto d-flex">
  //                <v-col cols="12" md="6" class="pt-5 pb-2">
  //                   <v-text-field  type="number"
  //                     v-model="item.max_member"
  //                     dense
  //                     outlined
  //                     :rules="[v => !!v || 'Max Member is required',v => (v >= 3) || 'Member must have minimum 3.' ]"

  //                   >
  //                     <template #label>
  //                       Max Member Allowed:
  //                       <span class="red--text">
  //                         <strong>*</strong>
  //                       </span>
  //                     </template>
  //                   </v-text-field>
  //                 </v-col>
  //               </v-row>
};
