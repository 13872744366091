<template>
  <!-- Card Start -->
  <v-container fluid class="pa-4">
    <v-overlay :value="isLoaderActive" color="primary">
      <v-progress-circular
        indeterminate
        size="50"
        color="primary"
      ></v-progress-circular>
    </v-overlay>
    <v-card class="mb-10">
      <v-card-actions>
        <v-row class="ml-4 mr-4 mt-1 mb-4">
          <v-toolbar-title dark color="primary">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  <strong>{{ entity }}</strong>
                </v-list-item-title>
                <v-list-item-subtitle
                  >Home <v-icon>mdi-chevron-right</v-icon> {{ module }}
                  <v-icon>mdi-chevron-right</v-icon>
                  {{ entity }}</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-btn
          :disabled="tableDataLoading"
          class="white--text primary-button mx-0 d-none d-md-block mr-4 mt-4"
          @click="
            isAddEdit = true;
            showAddEditDialog(null);
          "
        >
          Admit A Patient
          <v-icon right dark> mdi-plus </v-icon>
        </v-btn>

        </v-row>

        <v-spacer></v-spacer>
      </v-card-actions>
      
      <transition name="fade" mode="out-in">
        <v-data-table
          :headers="tableHeader"
          :items="dataTableRowNumbering"
          item-key="id"
          dense
          :options.sync="pagination"
          class="elevation-0"
          :loading="tableDataLoading"
          :loading-text="tableLoadingDataText"
          :server-items-length="totalItemsInDB"
          :items-per-page="25"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 75, 100],
          }"
        >
          <template v-slot:no-data>
            <p class="font-weight-black bold title" style="color: red">
              No Data Found
            </p>
          </template>

          <template v-slot:top>
            <v-toolbar flat>
              <v-text-field
                class="mt-4"
                v-model="searchText"
                label="Search"
                placeholder="Enter Mobile Number and press ENTER to search"
                prepend-inner-icon="mdi-magnify"
                @keydown.enter="searchInfo"
              ></v-text-field>
              <v-spacer></v-spacer>
              <span class="text-right mx-1 d-none d-md-block">
                <strong class="primary--text">
                  {{ totalItemsInDB }}
                </strong>
                Records Found
              </span>
             
            </v-toolbar>
          </template>

          <<template v-slot:item.actions="{ item }">
            <v-icon
              size="22"
              class="mx-1 fitPotPrimaryIcon"
              @click="
                isAddEdit = false;
                showAddEditDialog(item);
              "
              >mdi-square-edit-outline</v-icon
            >

            
          </template>

        </v-data-table>
      </transition>

       <!--start of Add / edit -->
      <v-dialog
        transition="dialog-top-transition"
        v-model="addEditDialog"
        max-width="400"
        scrollable
        :fullscreen="$vuetify.breakpoint.smAndDown"
        persistent
      >
        <template v-slot:default="dialog">
          <v-overlay :value="isDialogLoaderActive" color="primary">
            <v-progress-circular
              indeterminate
              size="50"
              color="primary"
            ></v-progress-circular>
          </v-overlay>
          <v-card>
            <v-toolbar
              color="primary"
              dark
              :max-height="$vuetify.breakpoint.smAndDown ? 56 : '20vh'"
            >
              <v-toolbar-title class="popup-header">{{
                addEditText
              }}</v-toolbar-title>
              <v-spacer></v-spacer><v-spacer></v-spacer>
              <v-btn icon dark @click="addEditDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="py-4 px-2">
              <v-form
                ref="holdingFormAddEdit"
                v-model="isFormAddEditValid"
                lazy-validation
              >
                

              

              <template v-if="isAddEdit == true">
                <v-row class="mx-auto d-flex">
                  <v-col cols="12" md="12" class="pb-1">
                    <v-autocomplete
                      v-model="item.patient_id"
                      :items="PatientList"
                      item-text="patient_name_mobile"
                      item-value="patient_id"
                      outlined
                      dense
                      chips
                      small-chips
                      :rules="validationRulesRequired"
                      ><template #label>
                        Select Patient
                        <span class="red--text">
                          <strong>*</strong>
                        </span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="mx-auto d-flex">
                    <v-col cols="12" md="12" class="pt-2 pb-2">
                      <v-text-field
                        v-model="item.uhid"
                        dense
                        outlined
                        :rules="validationRulesRequired"
                      >
                        <template #label>
                          UHID
                          <span class="red--text">
                            <strong>*</strong>
                          </span>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
              </template>
              <template v-else>
                <input type="hidden" name="patient_id" class="form-control" v-model="item.patient_id">

                <label class="pl-4" style="font-size:16px;"><strong>Patient Name: </strong>{{item.patient_full_name}}</label>
                <br /><br />
                <input type="hidden" name="uhid" class="form-control" v-model="item.uhid">

                <label class="pl-4" style="font-size:16px;"><strong>UHID: </strong>{{item.uhid}}</label>  
                <br /><br />             
              </template>

              
                <v-row class="mx-auto d-flex">
                  <v-col cols="12" md="6" class="pb-1">
                    <v-autocomplete
                      v-model="item.treatment_type"
                      :items="TreatmentTypeOptions"
                      item-text="item.treatment_type"
                      item-value="item.treatment_type"
                      outlined
                      dense
                      chips
                      small-chips
                      :rules="validationRulesRequired"
                      ><template #label>
                        Treatment Type
                        
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>

                <v-row class="mx-auto d-flex">
                  <v-col cols="12" md="12" class="pt-2 pb-2">
                    <v-text-field
                      v-model="item.bed_number"
                      dense
                      outlined
                      :rules="validationRulesRequired">
                      <template #label>
                        Bed Number
                        </template>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row class="mx-auto d-flex">
                 <v-col cols="12" md="12" class="pt-2">
                    <v-textarea
                      v-model="item.case_history"
                      dense
                      outlined
                      :rules="validationRulesRequired">
                      <template #label>
                        Case History
                        
                      </template>
                    </v-textarea>
                  </v-col>
                </v-row>
               
                <v-row class="mx-auto d-flex">
                  <v-col cols="12" md="6" class="pb-1">
                    <v-autocomplete
                      v-model="item.high_risk"
                      :items="HighRiskOptions"
                      item-text="item.high_risk"
                      item-value="item.high_risk"
                      outlined
                      dense
                      chips
                      small-chips
                      :rules="validationRulesRequired"
                      ><template #label>
                        High Risk
                        
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-row>
            

                <v-row class="mx-auto d-flex">
                 <v-col cols="12" md="12" class="pt-2">
                    <v-menu
                      ref="menu_patient_admin_date"
                      v-model="menu_patient_admin_date"
                      :close-on-content-click="false"
                      :return-value.sync="patient_admin_date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, arstt }">
                        <v-text-field
                          v-model="item.patient_admin_date"
                          label="Select Admit Date"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                          :rules="validationRulesRequired"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="item.patient_admin_date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menu_patient_admin_date = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.menu_patient_admin_date.save(patient_admin_date)
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>


            <!--  <v-row class="mx-auto d-flex">
                 <v-col cols="12" md="12" class="pt-2">
                    <v-menu
                      ref="menu_patient_discharge_date"
                      v-model="menu_patient_discharge_date"
                      :close-on-content-click="false"
                      :return-value.sync="patient_discharge_date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="patient_discharge_date"
                          label="Select Discharge Date"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          dense
                          outlined
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="patient_discharge_date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menu_patient_discharge_date = false"
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs.menu_patient_discharge_date.save(patient_discharge_date)
                          "
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <template v-if="isAddEdit == false">
                  <v-row class="mx-auto d-flex">
                    <v-col cols="12" md="6" class="pb-1">
                      <v-autocomplete
                        v-model="item.status"
                        :items="StatusOptions"
                        item-text="status"
                        item-value="status"
                        outlined
                        dense
                        chips
                        small-chips><template #label>
                          Status
                          
                        </template>
                      </v-autocomplete>
                    </v-col>
                  </v-row>
              </template>
              
                

              <v-row class="mx-auto d-flex">
                  <v-col cols="12" md="12" class="pt-2 pb-2">
                    <v-text-field
                      v-model="item.days_after_discharge_chat_window"
                      dense
                      outlined>
                      <template #label>
                        No Of Days After Discharge Close Chat Window
                        
                      </template>
                    </v-text-field>
                  </v-col>
              </v-row> -->

              </v-form>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end pt-2 pb-6">
              <v-btn class="mx-2 secondary-button" @click="close()"
                >Close</v-btn
              >
              <v-btn
                class="mx-2 primary-button"
                @click="addEditItem()"
                :disabled="!isFormAddEditValid"
              >
                {{ addUpdateButtonText }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <!--end of add/edit -->


      
      <!-- Card End -->
    </v-card>
  </v-container>
</template>
<script>
import { registerPatient } from "../registerPatient/registerPatient";
export default registerPatient;
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.9s;
  transition-property: opacity;
  transition-timing-function: ease;
}


.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
