import { Global } from "@/helpers/global";
import { globalMixin } from "../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../helpers/apiEndPoint";
export const clinicstaff = {
  props: ["userPermissionDataProps", "addDoctorDataProps"],
  mixins: [globalMixin],
  //#region  Data section
  data() {
    return {
      // @keypress="acceptNotCharacter" add line vue file select box
      //Add Link Hide
      checked1: false,
      checked2:false,
      checked3: false,
      // Data Table
 	
      // CityId:
      //   this.addDoctorDataProps != null 
      //     ? this.addDoctorDataProps.item.city_id.split(",").map(Number)
      //     : null,
      // AreaId:
      //   this.addDoctorDataProps != null 
      //     ? this.addDoctorDataProps.item.area_id.split(",").map(Number)
      //     : null,
      // DoctorCategoryId:
      //   this.addDoctorDataProps != null 
      //     ? this.addDoctorDataProps.item.disease_category_id.split(",").map(Number)
      //     : null,
      
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        {
          text: "Name",
          value: "clinicname",
          width: "15%",
          sortable: true,
          align: "start",
        },
        {
          text: "Role",
          value: "name",
          width: "15%",
          sortable: true,
          align: "start",
        },
        
        {
          text: "Status",
          value: "is_active",
          sortable: false,
          width: "15%",
          align: "start",
        },

        {
          text: "Actions",
          value: "actions",
          sortable: false,
          width: "15%",
          align: "end",
        },
      ],

      pagination: {},
      entity: "Clinic Staff",
      searchText: "", // search
      totalItemsInDB: 0,
      item: {},
      addEditDialog: false,
      isFormAddEditValid: false,
      isAddEdit: true,
      addUpdateButtonText: "Add Doctor",
      addEditText: "Add",
      isLoaderActive: false,
      isDialogLoaderActive: false,

      tableItems: [],
      cityItems: [],
      areaItems: [],
      doctorCategoryItems: [],
      imageRule: [],
      doctorProfileImage: null,
      profileImageUrl: null,
      tableDataLoading: false,

      //end

      //excel
      excelFields: {
        ID: "id",
        Name: "clinicname",
        Role: "name",       
      },
      excelFileName:
        // "Doctor" + "_" + moment().format("DD/MM/YYYY") + ".xls",
       "Clinicstaff" + ".xls",
      //end
    };

  },
  //#endregion

  //#region  created section
  created() {
    //  get the Doctor Details List
    this.profileImageUrl = Global.profileImageUrl;
    this.getDoctorDetailsList();


  },
  //#endregion

  //#region Computed section
  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  //#endregion

  //#region watch setion
  watch: {
    //#region  add/edit dialog
    addEditDialog(value) {
      return value ? true : this.close();
    },
    //#endregion

    //#region 
    doctorProfileImage(val) {
      this.doctorProfileImage = val;
      this.imageRule =
        this.doctorProfileImage != null
          ? [(v) => !v || v.size <= 1048576 || "File size should be 1MB"]
          : [];
    },
    //#endregion

    //#region Pagination
    pagination: {
      handler() {
        this.getDoctorDetailsList();
      },
      deep: true,
    },
    //#endregion
  },
  //#endregion


  //#region loading City, Area on page load/ mount 
  mounted() {
    // Show Add in dialog
    // this.getCity();
    this.getDiseaseCategory();
    this.getNursing();
    this.getJuniorDoctor();

  },
  //#endregion

  //#region Method section
  methods: {

  	//Add Link Hide Code
    hidecont() {
      this.checked1 = true;
      this.checked2 = false;
      this.checked3 = false;
      this.NursingCategoryId=[];
      this.JuniordoctorCategoryId=[];
      
    },
    hideconts() {
      this.checked1 = false;
      this.checked2 = true;
      this.checked3 = false;
      this.DoctorCategoryId=[];
      this.NursingCategoryId=[];
    },

    hidecontscheck() {
      this.checked1 = false;
      this.checked2 = false;
      this.checked3 = true;
      this.DoctorCategoryId=[];
      this.JuniordoctorCategoryId=[];
      
    },

    //#region To get the Doctor Details List
    getDoctorDetailsList() {
      // console.log('test');return;
      this.tableDataLoading = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "desc" : "asc";
      sortBy = sortBy.length == 0 ? `doctor_id` : sortBy[0];
      ApiService.get(ApiEndPoint.Clinicstaff.webGetDoctor, {
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
      })
        .then((response) => {
          console.log(response);
          this.tableDataLoading = false;
          this.tableItems = response.data.resultData.data;
          // console.log(this.tableItems);
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          console.log(error);return;
          this.tableDataLoading = false;
          if (error.response.status != 401 && error.response.status != 403) {
            this.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    //#endregion

    //#region  search
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getDoctorDetailsList();
      }, 500);
    },
    //#endregion

    //#region  show add/edit dialog
    async showAddEditDialog(item) {

      // Show Add
      if (item == null && this.isAddEdit == true) {
        this.addEditText = `Add ${this.entity}`;
        this.addEditDialog = true;
        this.addUpdateButtonText = " Add ";
        // this.CityId = null;
        // this.AreaId = null;
        this.DoctorCategoryId = null;
        this.JuniordoctorCategoryId = null;
        this.NursingCategoryId = null;
      } else {
        // Show Edit (Update)
        this.item = Object.assign({}, item);
        this.addEditText = `Edit ${this.entity} : ` + item.doctor_full_name;
        this.addEditDialog = true;
        this.addUpdateButtonText = "Update";
        // this.getCity();
        // this.getArea();
        this.getDiseaseCategory();
        this.getNursing();
        this.getJuniorDoctor();
      }
    },
    //#endregion

    //#region  to load City
    // getCity() {
    //   this.isDialogLoaderActive = true;
    //   ApiService.get(
    //     ApiEndPoint.Doctor.getCityIdWithoutPagination,
    //     {}
    //   )
    //     .then((response) => {
    //       this.isDialogLoaderActive = false;
    //       this.cityItems = response.data.resultData;
    //       this.CityId=this.item != null  ? this.item.city_id.split(",").map(Number) : null;
    //     })
    //     .catch((error) => {
    //       this.isDialogLoaderActive = false;
    //       if (error.response.status != 401 && error.response.status != 403) {
    //         this.showErrorAlert(true, "error", "Something went wrong");
    //       }
    //     });
    // },
    //#endregion


    //#region  to load Area
    // async getArea() {
    //   this.isDialogLoaderActive = true;
    //   try {

    //     // this.CityId = this.item != null 
    //     //   ? this.item.city_id.split(",").map(Number)
    //     //   : null

    //     const response = await ApiService.get(
    //       ApiEndPoint.Doctor.getAreaIdWithoutPagination,
    //       {city_id: this.CityId!=null? this.CityId.toString():null}
    //     )
    //     this.areaItems = response.data.resultData;
    //     this.isDialogLoaderActive = false;
    //     this.AreaId=this.item != null  ? this.item.area_id.split(",").map(Number) : null;

    //   } catch (error) {
    //     this.isDialogLoaderActive = false;
    //     if (error.response.status != 401 && error.response.status != 403) {
    //       this.showErrorAlert(true, "error", "Something went wrong");
    //     }
    //   }
    // },
    //#endregion

    //#region  to load City
    async getDiseaseCategory() {
      this.isDialogLoaderActive = true;
      try {
        const response = await ApiService.get(
          ApiEndPoint.Clinicstaff.webGetDoctorCategory,
          {}
        )
        this.isDialogLoaderActive = false;
        this.doctorCategoryItems = response.data.resultData;
        
        this.DoctorCategoryId=this.item != null 
          ? this.item.doctor_id.split(",").map(Number)
          : null

      } catch (error) {
        this.isDialogLoaderActive = false;
        if (error.response.status != 401 && error.response.status != 403) {
          this.showErrorAlert(true, "error", "Something went wrong");
        }
      }
    },
    //#endregion

    //#region  to load getJuniorDoctor
    async getJuniorDoctor() {
      this.isDialogLoaderActive = true;
      try {
        const response = await ApiService.get(
          ApiEndPoint.Clinicstaff.webGetJuniorCategory,
          {}
        )
        this.isDialogLoaderActive = false;
        this.juniordoctorCategoryItems = response.data.resultDatanursing;
        
        this.JuniordoctorCategoryId=this.item != null 
          ? this.item.id.split(",").map(Number)
          : null

      } catch (error) {
        this.isDialogLoaderActive = false;
        if (error.response.status != 401 && error.response.status != 403) {
          this.showErrorAlert(true, "error", "Something went wrong");
        }
      }
    },
    //#endregion

    //#region  to load getNursing
    async getNursing() {
      this.isDialogLoaderActive = true;
      try {
        const response = await ApiService.get(
          ApiEndPoint.Clinicstaff.webGetNursingCategory,
          {}
        )
        this.isDialogLoaderActive = false;
        this.nursingCategoryItems = response.data.resultDatanursing;
        
        this.NursingCategoryId=this.item != null 
          ? this.item.staff_id.split(",").map(Number)
          : null

      } catch (error) {
        this.isDialogLoaderActive = false;
        if (error.response.status != 401 && error.response.status != 403) {
          this.showErrorAlert(true, "error", "Something went wrong");
        }
      }
    },
    //#endregion

    //#region  add/edit item
    addEditItem() {

      if (this.$refs.holdingFormAddEdit.validate()) {
        if (this.isAddEdit) {
          // save
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          
        
          postData.append("DoctorCategoryId", this.DoctorCategoryId!=null? this.DoctorCategoryId.toString():null);
          postData.append("JuniordoctorCategoryId", this.JuniordoctorCategoryId!=null? this.JuniordoctorCategoryId.toString():null);
          postData.append("NursingCategoryId", this.NursingCategoryId!=null? this.NursingCategoryId.toString():null);
          
          // if (this.doctorProfileImage != null) {
          //   postData.append("doctor_profile_image", this.doctorProfileImage);
          // }
          // postData.append("CityId", this.CityId!=null? this.CityId.toString():null);
          // postData.append("AreaId", this.AreaId!=null? this.AreaId.toString():null);
          
          // postData.append("doctor_overall_experience", this.item.doctor_overall_experience);
          // postData.append("doctor_first_name", this.item.doctor_first_name);
          // postData.append("doctor_last_name", this.item.doctor_last_name);
          // postData.append("specialization_name", this.item.specialization_name);
          // postData.append("education_name", this.item.education_name);
          // postData.append("doctor_mobile_number", this.item.doctor_mobile_number);
          // postData.append("doctor_description", this.item.doctor_description);
          // postData.append("experience_name", this.item.experience_name);

          ApiService.post(ApiEndPoint.Clinicstaff.saveclinicstaff, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDoctorDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        } else {

          //update
          this.isDialogLoaderActive = true;
          let postData = new FormData();
          if (this.doctorProfileImage != null) {
            postData.append("doctor_profile_image", this.doctorProfileImage);
          }
          postData.append("city_id", this.CityId!=null? this.CityId.toString():null);
          postData.append("AreaId", this.AreaId!=null? this.AreaId.toString():null);
          postData.append("DoctorCategoryId", this.DoctorCategoryId!=null? this.DoctorCategoryId.toString():null);

          postData.append("doctor_overall_experience", this.item.doctor_overall_experience);
          postData.append("doctor_first_name", this.item.doctor_first_name);
          postData.append("doctor_last_name", this.item.doctor_last_name);
          postData.append("specialization_name", this.item.specialization_name);
          postData.append("education_name", this.item.education_name);
          postData.append("doctor_mobile_number", this.item.doctor_mobile_number);
          postData.append("doctor_description", this.item.doctor_description);
          postData.append("experience_name", this.item.experience_name);
          postData.append("doctor_id", this.item.doctor_id);

          ApiService.post(ApiEndPoint.Doctor.webUpdateDoctorDetails, postData)
            .then((response) => {
              this.isDialogLoaderActive = false;
              this.close();
              if (response.data.success == "true") {
                Global.showSuccessAlert(true, "success", response.data.message);
                this.getDoctorDetailsList();
              } else if (response.data.result == "error") {
                Global.showErrorAlert(true, "error", response.data.message);
              }
            })
            .catch((error) => {
              this.isDialogLoaderActive = false;

              if (
                error.response.status != 401 ||
                error.response.status != 403
              ) {
                Global.showErrorAlert(true, "error", "Something went wrong");
              }
            });
        }
      }
    },

    //#endregion

    //#region  to close the dialog
    close() {
      this.addEditDialog = false;
      setTimeout(() => {
        this.item = Object.assign({}, {
          cityItems: this.cityItems,
          areaItems: this.areaItems,
        });
      }, 300);
      window.location.reload();
    },
    //#endregion

    //#region  enable disable
    async enableDisableItem(item) {
      const result = await Global.showConfirmationAlert(
        `Change  ${this.entity} : ${item.doctor_full_name} Status`,
        "Are you sure to change the status",
        "warning"
      );
      if (result.isConfirmed) {
        this.isLoaderActive = true;

        ApiService.post(
          ApiEndPoint.Clinicstaff.changeClinicDetailsStatus, {
          clinic_id: item.id,
          is_active: item.is_active,
        }
        )
          .then((response) => {
            this.isLoaderActive = false;
            if (response.data.success == "true") {
              Global.showSuccessAlert(true, "success", response.data.message);
              this.getDoctorDetailsList();
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;

            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        if (item.is_active == false) {
          item.is_active = true;
        } else {
          item.is_active = false;
        }
      }
    },
    //#endregion

    //#region Delete Item
    async deleteItem(item) {
      const result = await Global.showConfirmationAlert(
        `Delete ${this.entity} ${item.name}`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
        this.isLoaderActive = true;
        ApiService.post(ApiEndPoint.Clinicstaff.deleteClinicDetails, {
          clinic_id: item.id,
          clinic_name:item.name
          })
          .then((response) => {
            this.isLoaderActive = false;
            if (response.data.success == "true") {
              Global.showSuccessAlert(true, "success", response.data.message);
              this.getDoctorDetailsList();
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;

            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      }
    },
    //#endregion
  },
  //#endregion
};
