import { Global } from "@/helpers/global";
import { globalMixin } from "../../../../mixins/globalMixin";
import { ApiService } from "@/helpers/apiService";
import { ApiEndPoint } from "../../../../helpers/apiEndPoint";
export const viewGroupDetails = {
  props: ["userPermissionDataProps"],
  mixins: [globalMixin],
  data() {
    return {
      // Data Table
      tableLoadingDataText: "Loading data",
      tableHeader: [
        {
          text: "#",
          value: "index",
          width: "5%",
          sortable: false,
          align: "start",
        },
        
        {
          text: "Name",
          value: "member_name",
          width: "10%",
          sortable: true,
          align: "start",
        },

         {
          text: "Member Type",
          value: "role",
          width: "10%",
          sortable: true,
          align: "start",
        },
       
        {
          text: "Status",
          value: "status",
          width: "10%",
          sortable: false,
          align: "start",
        },
         
         {
          text: "Actions",
          value: "actions",
          width: "10%",
          sortable: false,
          align: "start",
        },
      ],
      tableItems: [],
      totalItemsInDB: 0 ,
      tableDataLoading: false,
      isLoaderActive: false,
      pagination: {},
      module: "Admitted",
      entity: "Group Members",
      
      // search
      searchText: "",

      
    };
  },
  //#region created
  created() {
    // To get Details in List
    this.group_id = this.$route.query.group_id;
    this.getDetails();
  },
  //#endregion

  //#region computed
  computed: {
    // For numbering the Data Table Rows
    dataTableRowNumbering() {
      return this.tableItems.map((items, index) => ({
        ...items,
        index: index + 1,
      }));
    },
  },
  //#endregion

  //#region watch
  watch: {
    pagination: {
      handler() {
        this.getDetails();
      },
      deep: true,
    },
  },
  //#endregion
  //#region 
  methods: {

      //#region  enable disable
    async enableDisableItem(item) {
      const result = await Global.showConfirmationAlert(
        `Change  ${this.entity} : ${item.member_name} Status`,
        "Are you sure to change the status",
        "warning"
      );
      if (result.isConfirmed) {
        this.isLoaderActive = true;

        ApiService.post(
          ApiEndPoint.chatGroup.changeMemberStatus, {
          member_id: item.member_id,
          status: item.status,
        }
        )
          .then((response) => {
            this.isLoaderActive = false;
            if (response.data.success == "true") {
              Global.showSuccessAlert(true, "success", response.data.message);
              this.getDoctorDetailsList();
            } else if (response.data.result == "error") {
              Global.showErrorAlert(true, "error", response.data.message);
            }
          })
          .catch((error) => {
            this.isLoaderActive = false;

            if (error.response.status != 401 || error.response.status != 403) {
              Global.showErrorAlert(true, "error", "Something went wrong");
            }
          });
      } else {
        if (item.status == false) {
          item.status = true;
        } else {
          item.status = false;
        }
      }
    },
    //#endregion

    //#region To get the Details list
    getDetails() {
      this.isLoaderActive = true;
      let { page, itemsPerPage, sortDesc, sortBy } = this.pagination;
      sortDesc = sortDesc.length > 0 && sortDesc[0] ? "asc" : "desc";
      sortBy = sortBy.length == 0 ? `group_id` : sortBy[0];
      ApiService.post(ApiEndPoint.chatGroup.getAllGroupMembers, {
        group_id:this.group_id,
        itemsPerPage: itemsPerPage,
        sortColumn: sortBy,
        sortOrder: sortDesc,
        page: page,
        searchText: this.searchText,
       
        
      })
        .then((response) => {
          this.isLoaderActive = false;
          this.tableItems = response.data.resultData;
          this.totalItemsInDB = response.data.resultData.total;
        })
        .catch((error) => {
          this.isLoaderActive = false;
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },

   // delete
    async deleteItem(item) {
      const result = await Global.showConfirmationAlert(
        `Delete ${item.member_name}`,
        "Are you sure to delete",
        "warning"
      );
      if (result.isConfirmed) {
        this.apiCallPost(ApiEndPoint.chatGroup.deleteMemberByAdmin, {
          member_id: item.member_id,
        });
      }
    },

     apiCallPost(endPoint, parameter) {
      ApiService.post(endPoint, parameter)
        .then((response) => {
          this.isLoaderActive = false;
          if (response.data.success == "true") {
            Global.showSuccessAlert(true, "success", response.data.message);
            this.getDetails();
          } else if (response.data.result == "error") {
            Global.showErrorAlert(true, "error", response.data.message);
          }
        })
        .catch((error) => {
          this.isLoaderActive = false;
          console.log(error);
          if (error.response.status != 401 || error.response.status != 403) {
            Global.showErrorAlert(true, "error", "Something went wrong");
          }
        });
    },
    
    //#region search Info
    searchInfo() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.getDetails();
      }, 500);
    },
    //#endregion
  },
  //#endregion
};
